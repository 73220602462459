import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { SharedHttpService } from "./shared-http.service";
import { StorageService } from "./storage.service";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from "@angular/router";
import { CONST } from "../shared.constant";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { LandingPageService } from './landing-page.service';
import { ClevertapService } from './clevertap.service';
import { filter, pairwise, map } from 'rxjs/operators';
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import * as JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { FormGroup } from "@angular/forms";
import { isPlatformBrowser } from "@angular/common";
declare function callLoadingFunctions(): any;
declare function fractionSlider(): any;
declare function swipertab(): any;
declare function loadMore(): any;
declare function normalSlider(): any;
declare function normalSlider3(): any;
declare function expandcollapse(): any;
declare function customDropdown(): any;
declare function googleMaps(): any;
declare function loader(): any;
declare function accordianWithSlider(slideName): any;
declare function venueList(): any;
declare function rangeSlider(sliderName): any;
declare function sidebarDrop(): any;
declare function dropdownActiveClass() : any;
declare function videoPopup(): any;
declare function pressReleaseKitClick() : any;
declare function dateTimePicker(): any;
declare function callNavFunction(): any;
declare function navigationScroll(): any;
declare function mobileSubnavigationText(): any;
declare function windowNotificationClose(): any;
declare function windowNotification(): any;
declare function galleryPopupSlider(): any;
declare function backToTop(): any;
declare function inquiryPeopleRangeSlider(): any;


const CleverTapKeys = {
  cta: {
    eventName: 'CTCTAEventName',
    pageSource: 'CTCTAPageSource',
    linkClickedValue: 'CTCTALinkClickedValue',
    ctSource: 'CTCTACTSource',
    subHeading: 'CTCTASubHeading'
  },
  cta1: {
    eventName: 'CTCTA1EventName',
    pageSource: 'CTCTA1PageSource',
    linkClickedValue: 'CTCTA1LinkClickedValue',
    ctSource: 'CTCTA1CTSource',
    subHeading: 'CTCTA1SubHeading'
  },
  cta2: {
    eventName: 'CTCTA2EventName',
    pageSource: 'CTCTA2PageSource',
    linkClickedValue: 'CTCTA2LinkClickedValue',
    ctSource: 'CTCTA2CTSource',
    subHeading: 'CTCTA2SubHeading'
  },
  cta3: {
    eventName: 'CTCTA3EventName',
    pageSource: 'CTCTA3PageSource',
    linkClickedValue: 'CTCTA3LinkClickedValue',
    ctSource: 'CTCTA3CTSource',
    subHeading: 'CTCTA3SubHeading'
  },
  cta4: {
    eventName: 'CTCTA4EventName',
    pageSource: 'CTCTA4PageSource',
    linkClickedValue: 'CTCTA4LinkClickedValue',
    ctSource: 'CTCTA4CTSource',
    subHeading: 'CTCTA4SubHeading'
  }
}

@Injectable({ providedIn: "root" })
export class SharedService {
  private userImage: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private previousUrl;
  private currentUrl;
  fragment: string;
  previousRouteData;
  public inquiryApiErrorMsg = new Subject<string>();
  public disableSubmitBtn = new Subject<boolean>();
  public configData: any = {};
  player360Script;
  checkBrowser = false;

  constructor(
    private _httpService: SharedHttpService,
    private _storageService: StorageService,
    private _router: Router,
    private _landingPageService: LandingPageService,
    private _clevertapService: ClevertapService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title, 
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) platformId: string
    // private cookieService: CookieService
  ) {
    this.checkBrowser = isPlatformBrowser(platformId);
    // this.currentUrl = this._router.url;
    activatedRoute.fragment.subscribe((fragment: string) => {
      this.fragment = (fragment) ? fragment : '';
      if (!this.fragment && this.checkBrowser) window.scroll(0, 0);
    });
    // _router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {        
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = this._router.url;
    //     console.log("data", this.activatedRoute.snapshot.data);
    //   };
    // });
    _router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise(), )
      .subscribe((events: RoutesRecognized[]) => {
        // this.previousUrl = events[0];
        // this.currentUrl = events[1];
        // console.log('previousUrl', events[0]);
        // console.log('currentUrl', events[1]);
        this.previousRouteData = events;
        // console.log("previousRouteData: ", events);
      });
  }

  load360Function() {
    this.player360Script = document.createElement('script');
    this.player360Script.src = "assets/vendor/js/360.js";
    this.player360Script.type = 'module';
    document.getElementsByTagName('body')[0].appendChild(this.player360Script);
  }

  getHttpService() {
    return this._httpService;
  }

  getStorageService() {
    return this._storageService;
  }

  getCommonService() {
    return this._landingPageService;
  }
  getCleverTapService() {
    return this._clevertapService;
  }

  initializeCustomJs() {
    if (this.checkBrowser) {
      setTimeout(() => {
        callLoadingFunctions();
        this.scroll();
      }, 1000)
    }
  }

  callFractionSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        fractionSlider();
      }, 200)
    }
  }

  callSwipertab() {
    if (this.checkBrowser) {
      setTimeout(() => {
        swipertab();
      }, 200)
    }
  }

  callNormalSlider3() {
    if (this.checkBrowser) {
      setTimeout(() => {
        normalSlider3();
      }, 200)
    }
  }
  callNormalSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        normalSlider();
      }, 200)
    }
  }
  callCustomDropdown() {
    if (this.checkBrowser) {
      setTimeout(() => {
        customDropdown();
      }, 200);
    }
  }

  callGoogleMaps() {
    if (this.checkBrowser) {
      setTimeout(() => {
        googleMaps();
      }, 200);
    }
  }

  callLoadMore() {
    if (this.checkBrowser) {
      setTimeout(() => {
        loadMore();
      }, 200)
    }
  }

  callExpandcollapse() {
    if (this.checkBrowser) {
      setTimeout(() => {
        expandcollapse();
      }, 200)
    }
  }
  callLoader() {
    if (this.checkBrowser) {
      setTimeout(() => {
        loader();
      }, 200)
    }
  }
  callAccordianWithSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        accordianWithSlider("accordian_slider");
      }, 200)
    }
  }
  callVenueList() {
    if (this.checkBrowser) {
      setTimeout(() => {
        venueList();
      }, 200)
    }
  }
  callPeopleRangeSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        rangeSlider("peopleRangeSlider");
      }, 200)
    }
  }
  callsidebarDrop() {
    if (this.checkBrowser) {
      setTimeout(() => {
        sidebarDrop();
      }, 200)
    }
  }
  calldropdownActiveClass() {
    if (this.checkBrowser) {
      setTimeout(() => {
        dropdownActiveClass();
      }, 200)
    }
  }
  callVideoPopup() {
    if (this.checkBrowser) {
      setTimeout(() => {
        videoPopup();
      }, 200)
    }
  }
  callPressReleaseKitClick() {
    if (this.checkBrowser) {
      setTimeout(() => {
        pressReleaseKitClick();
      }, 200)
    }
  }

  callCustomDateTimePicker() {
    if (this.checkBrowser) {
      setTimeout(() => {
        dateTimePicker();
      }, 200)
    }
  }

  callNavFunction() {
    if (this.checkBrowser) {
      setTimeout(() => {
        callNavFunction();
      }, 200)
    }
  }

  callNavigationScroll() {
    if (this.checkBrowser) {
      setTimeout(() => {
        navigationScroll();
      }, 200)
    }
  }

  callMobileSubnavigationText() {
    if (this.checkBrowser) {
      setTimeout(() => {
        mobileSubnavigationText();
      }, 200)
    }
  }

  callWindowNotificationClose() {
    if (this.checkBrowser) {
      setTimeout(() => {
        windowNotificationClose();
      }, 200)
    }
  }

  callWindowNotification() {
    if (this.checkBrowser) {
      setTimeout(() => {
        windowNotification();
      }, 200)
    }
  }

  callGalleryPopupSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        galleryPopupSlider();
      }, 200)
    }
  }

  callBackToTop() {
    if (this.checkBrowser) {
      setTimeout(() => {
        backToTop();
      }, 200)
    }
  }

  callInquiryPeopleRangeSlider() {
    if (this.checkBrowser) {
      setTimeout(() => {
        inquiryPeopleRangeSlider();
      }, 200)
    }
  }

  checkCTALink($event, link?, target = '_self', type = 'Internal', fileName?) {
    if (!link || link == '#') {
      $event.preventDefault();
    } else if (target == '[\"_blank\"]' || target == '_blank' || target == '[\"_self\"]' ||target == '_self') {
      target == '[\"_self\"]' || target == '_self' ? window.open(link, '_self') : window.open(link, target);
    }  else if (type == 'PDF') {
      let anchorLink = document.createElement("a");
      anchorLink.download = fileName;
      anchorLink.href = link;
      anchorLink.click();
    }
    else {
      this._router.navigateByUrl(link);
    }
    return false;
  }

  subHeaderItems(titleList?) {
    let subHeaderList = [];
    if (!titleList) {
      titleList = [
        "Exhibition Halls", "Floor Plans", "Highlights", "Planning Tools", "Events", "Showcase"
      ];
    }
    titleList.forEach((title, index) => {
      let obj = { id: (title.replace(/\s/g, '-')).toLowerCase(), title: title }
      subHeaderList.push(obj);
    });
    return subHeaderList;
  }

  getPdfName(string) {
    if (string) {
      let start = string.lastIndexOf('/') + 1;
      let end = string.indexOf('.');
      let pdfName = string.slice(start, end);
      // console.log("pdfname: ", pdfName);
      return pdfName;
    }
  }

  downloadAllDocuments(files, filename){
    // console.log("files", files);
    let count = 0;
    const zipFilename = filename+".zip";
    // Assign new zip object
    let zip = new JSZip()
     // Loop through the filelist to get each filename and pass each file to zip object
  //    for(let file of files){ 
  //     let start = file.lastIndexOf('/') + 1;
  //     let filename=file.slice(start);
  //     zip.file(filename, file)
  //     console.log("filename: ", filename);
  // }
   // Generate the complete zip file
  //  zip.generateAsync({type:'blob'}).then((blobdata)=>{
    // create zip blob file
    // let zipblob = new Blob([blobdata])

    // For development and testing purpose
    // Download the zipped file 
//     var elem = window.document.createElement("a")
//     elem.href = window.URL.createObjectURL(zipblob)
//     elem.download = 'compressed.zip'
//     elem.click()
// })
files.forEach(async function (url){
  const urlArr = url.split('/');
  // console.log("urlArr", urlArr);
  const filename = urlArr[urlArr.length - 1];
  // console.log("filename", filename);
  try {
    const file = await JSZipUtils.getBinaryContent(url)
    zip.file(filename, file, { binary: true});
    count++;
    if(count === files.length) {
      zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, zipFilename);
      });
    }
  } catch (err) {
    console.log(err);
  }
});
}

  logout() {
    // this.cookieService.delete("USER", "/", ".ril.com");
    // this.cookieService.delete("MYSSO", "/", ".ril.com");
    // this.cookieService.deleteAll();
    // sessionStorage.clear();
    // this._router.navigate(["/login"]);

    // this._httpService
    //   .getWSO2("loginUrl", CONST["apiEndPoint"]["logout"], null)
    //   .subscribe(
    //     (resp: any) => {
    //       this._router.navigate(["/login"]);
    //     },
    //     err => {
    //       this._router.navigate(["/login"]);
    //     }
    //   );
  }

  fetchToken() {
    this.getHttpService()
      .getToken("loginUrl", CONST["apiEndPoint"]["gettoken"])
      .subscribe(
        (response: any) => {
          this.getStorageService().setSessionData("Wso2Token", response);
          this.getImage();
          this._router.navigate(["/dashboard"]);
        },
        (error: any) => { }
      );
  }

  getImage() {
    // let obj = {
    //   id: this._storageService.getUserCookie()
    //     ? this._storageService.getUserCookie().domain_id
    //     : null,
    //   soapAction: "InfoByDomainID"
    // };
    // this.getHttpService()
    //   .postWSO2("wso2Url", CONST["apiEndPoint"]["userImage"], {
    //     id: "ankit.panchal",
    //     soapAction: "InfoByDomainID"
    //   })
    //   .subscribe(
    //     res => {
    //       if (res && res["ImageResponse"] && res["ImageResponse"].length > 0) {
    //         this.userImage.next(
    //           `data:image/png;base64,${res["ImageResponse"]}`
    //         );
    //       } else {
    //         this.userImage.next(null);
    //       }
    //     },
    //     err => {
    //       this.userImage.next(null);
    //     }
    //   );
  }

  getUserImage() {
    return this.userImage;
  }

  public getPreviousUrl() {
    if (this.previousRouteData) return this.previousRouteData[0];
    
    
  }
  public getCurrentUrl() {
    if (this.previousRouteData) return this.previousRouteData[1];
    
    
  }
  public getPreviousRouteData() {
    if (this.previousRouteData) {
      return this.previousRouteData[0].state.root.firstChild.data.page ? this.previousRouteData[0].state.root.firstChild.data.page : 'home';
    } else {
      return 'home';
    }
  }

  scroll() {
    if (this.fragment) {
      const elmnt = document.getElementById(this.fragment);
      elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  submitInquiryForm(payload, enquiryFormCTEventObj, profile, formData?) {
    this.disableSubmitBtn.next(true);
    if (formData) {
      this.getCommonService().submitCareersInquiryDetails(payload).subscribe(
        (data: any) => {
          this.disableSubmitBtn.next(false);
          this.getCleverTapService().createCTUserLogin(profile);
          this.getCleverTapService().recordSubscriptionEvent('Enquiry_Forms', enquiryFormCTEventObj);
          // console.log(enquiryFormCTEventObj);
          this._router.navigate(['./thank-you'], {
            state: { inqId: data.data }
          });
          // this.resetInquiryFormValues();
        },
        (error) => {
          this.disableSubmitBtn.next(false);
          console.log("Try after some time..");
          this.inquiryApiErrorMsg.next(error);
        }
      )
    } else {
      this.getCommonService().submitInquiryDetails(payload).subscribe(
        (data: any) => {
          this.disableSubmitBtn.next(false);
          this.getCleverTapService().createCTUserLogin(profile);
          this.getCleverTapService().recordSubscriptionEvent('Enquiry_Forms', enquiryFormCTEventObj);
          // console.log("On Success:", enquiryFormCTEventObj);
          this._router.navigate(['./thank-you'], {
            state: { inqId: data.data }
          });
          // this.resetInquiryFormValues();
        },
        (error) => {
          this.disableSubmitBtn.next(false);
          // console.log("On Error:", enquiryFormCTEventObj);
          console.log("Try after some time..");
          this.inquiryApiErrorMsg.next(error);
        }
      )
    }
  }
  resetInquiryFormValues() {
    this.getStorageService().setSessionData('name', '');
    this.getStorageService().setSessionData('email', '');
    this.getStorageService().setSessionData('company', '');
    this.getStorageService().setSessionData('message', '');
    this.getStorageService().setSessionData('phoneNo', '');
  }
  setInquiryFormValues(name, email, company, message, phoneNo?) {
    if (name) this.getStorageService().setSessionData('name', name);
    if (email) this.getStorageService().setSessionData('email', email);
    if (company) this.getStorageService().setSessionData('company', company);
    if (message) this.getStorageService().setSessionData('message', message);
    if (phoneNo) this.getStorageService().setSessionData('phoneNo', phoneNo);
  }
  getInquiryFormValues() {
    let obj = {
      name: this.getStorageService().getSessionData('name'),
      email: this.getStorageService().getSessionData('email'),
      company: this.getStorageService().getSessionData('company'),
      message: this.getStorageService().getSessionData('message'),
      phoneNo: this.getStorageService().getSessionData('phoneNo')
    }
    return obj;
  }
  callCTEvent(eventName, sectionName?, contentName?, staticLink?){
  if (this.checkBrowser) {
      let pageSource = this.titleService.getTitle();
      setTimeout(() => {
        // let previousUrl = this._sharedService.getPreviousUrl();
        let currentUrl = this.getCurrentUrl();
        let eveObj = {
            Page_Source: (pageSource == 'Jio World Centre - Get Directions') ? 'Jio World Centre - Visit Us' : pageSource,
            Event_Name: eventName
        };
        if (eventName != 'Download_Links') {
          if (staticLink){
            eveObj['Link_Clicked'] = staticLink;
          }else if(eventName == 'Header_Primary_Links'){
            eveObj['Link_Clicked_Value'] = currentUrl.state.root.firstChild.data.CTLinkClicked;
          }else {
            eveObj['Link_Clicked'] = currentUrl.state.root.firstChild.data.CTLinkClicked;
          }
        };
        if (sectionName) eveObj['Section_Name'] = sectionName;
        if (contentName) eveObj['Content_Name'] = contentName;
          this.getCleverTapService().recordSubscriptionEvent(eventName, eveObj);
        // console.log(eveObj);
    }, 500)
  }
  }

  callCTEventNew(eventName, eventObj: {
    pageSource: string,
    linkClickedValue: string,
    ctSource:string,
    subHeading: string,
    linkClicked?: string,
    sectionName?: string,
    contentName?: string,
    staticLink?: string
  }){
    
    let pageSource = eventObj.pageSource || this.titleService.getTitle();
    // setTimeout(() => {
      // let previousUrl = this._sharedService.getPreviousUrl();
      let currentUrl = this.getCurrentUrl();
      let eveObj = {
          Page_Source: (pageSource == 'Jio World Centre - Get Directions') ? 'Jio World Centre - Visit Us' : pageSource,
          Event_Name: eventName
      };

      if (eventName != 'Download_Links') {
        if (eventObj.staticLink){
          eveObj['Link_Clicked'] = eventObj.staticLink;
        }else if(eventName == 'Header_Primary_Links' && currentUrl){
          eveObj['Link_Clicked_Value'] = currentUrl?.state?.root?.firstChild?.data?.CTLinkClicked;
        }else if(currentUrl) {
          eveObj['Link_Clicked'] = currentUrl?.state?.root?.firstChild?.data?.CTLinkClicked;
        }
      };

      if(eventObj.linkClickedValue) eveObj['Link_Clicked_Value'] = eventObj.linkClickedValue;
      if(eventObj.linkClicked) eveObj['Link_Clicked'] = eventObj.linkClicked;
      if(eventObj.sectionName) eveObj['Section_Name'] = eventObj.sectionName;
      if(eventObj.contentName) eveObj['Content_Name'] = eventObj.contentName;
      if(eventObj.ctSource) eveObj['CT_Source'] = eventObj.ctSource;
      if(eventObj.subHeading) eveObj['Sub_Heading'] = eventObj.subHeading;

        this.getCleverTapService().recordSubscriptionEvent(eventName, eveObj);
      // console.log(eveObj);
  // }, 500)
  }

  updateSubscriptionCTInfo(data, subscriptionFlag, subscriptionEventName) {
    if (data.data) {
      let emailId = data.data.mailId;
      let unsubscriptionLink = data.data.unsubscriptionUrl;
      let eventObj = {};
      let updatedProfile = {
        Email: emailId
      };
      // console.log(updatedProfile);
      if (subscriptionFlag) {
        updatedProfile['Unsubscription Link'] = unsubscriptionLink;
        eventObj = {
          Act: "Y",
          Unsubscribe_link: unsubscriptionLink
        }
      } else {
        updatedProfile['Unsubscription Link'] = "";
        updatedProfile['MSG-email'] = false;
        eventObj = {
          Act: "N",
          Unsubscribe_link: ""
        }
      }
      this.getCleverTapService().createCTUserLogin(updatedProfile);
      this.getCleverTapService().recordSubscriptionEvent(subscriptionEventName, eventObj);
      // console.log(eventObj);
    }
  }
  handleFileInput(fileObject, form: FormGroup, fileSizeLimit, fileExtension) {
    // console.log("fileObject", fileObject);
    let fileSizeValid;
    let fileTypeValid;
    if (fileObject.length > 0) {
      let attachedFile = fileObject[0];
      fileSizeValid = this.isFileSizeValid(attachedFile, fileSizeLimit);
      fileTypeValid = this.isFileTypeValid(attachedFile, fileExtension);
      if (fileSizeValid === "" && fileTypeValid === "") {
        form.patchValue({
          file: attachedFile
        });
        form.get('file').updateValueAndValidity();
      } else {
        form.patchValue({
          file: ''
        });
      }
    }
    return form;
  }
  isFileSizeValid(fileObj, fileSizeLimitInBytes) {
    let totalSizeMB = fileSizeLimitInBytes / Math.pow(1024, 2);
    if (fileSizeLimitInBytes !== -1 && fileObj.size >= fileSizeLimitInBytes) {
      // this.fileSizeError = "File size limit should be " + Math.round(totalSizeMB) + "MB max";
      return "File size limit should be " + Math.round(totalSizeMB) + "MB max";
    } else if (fileObj.size == 0) {
      // this.fileSizeError = "File is empty";
      return "File is empty";
    } else {
      // this.fileSizeError = "";
      return "";
    }
  }

  isFileTypeValid(fileObj, fileExtensions) {
    const fileExtn = fileObj.name.split(".").pop();
    if (fileExtensions && fileExtensions.includes("." + fileExtn.toLowerCase())) {
      // this.fileTypeError = "";
      return "";
    }
    // this.fileTypeError = "Invalid file type";
    return "Invalid file type";
  }
  
  getSafeHTML(value: {} | string, addScriptTag = true) {

    const json = addScriptTag ? JSON.stringify(value, null, 2) : value;
    const html: string = addScriptTag ? `<script type="application/ld+json">${json}</script>` : json as string;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setConfigData(data : any){
    data?.forEach(element => {
      this.configData[element?.FieldName?.data] = element?.FieldValue?.data;
    });
  }

  openBookNowAsGuest(event, ctaLink, target, type, eventId) {
    const input = {
      showId: eventId,
      Guest: 'Y'
    }
    this.getHttpService().postLogin('tccUrl', CONST["apiEndPoint"]['encryptToken'], input).subscribe((res: any) => {
      const finalCtaLink = `${ctaLink}/${res?.data?.data}`;
      this.checkCTALink(event, finalCtaLink, target, type);
    })
  }

  getCleverTapKeysAndObject(event, key = 'cta') {
    const ctKeys = CleverTapKeys[key];
    const eventObj = {
      eventName:  event?.[ctKeys.eventName]?.data,
      pageSource: event?.[ctKeys.pageSource]?.data,
      linkClickedValue: event?.[ctKeys.linkClickedValue]?.data,
      ctSource: event?.[ctKeys.ctSource]?.data,
      subHeading: event?.[ctKeys.subHeading]?.data,
    }

    return {ctKeys, eventObj};
  }
}

