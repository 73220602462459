import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../../../shared/services/shared.service';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cec-wedding-details',
  templateUrl: './cec-wedding-details.component.html',
  styleUrls: ['./cec-wedding-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CecWeddingDetailsComponent implements OnInit {
  subscription;
  slugUrl;
  currentEvent;
  staticUrlForImgNew = environment.staticUrlForImgNew
  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute, private router:Router) {
    this.slugUrl = this.activatedRoute?.snapshot?.paramMap?.get('slug')
  }

  ngOnInit(): void {
    this.getPageContent();
  }

  getPageContent() {
    let key = [
      'CEC-WEDDINGS-CARDS'
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(key).subscribe(
      (data: any) => {
        console.log("=================",data)
        this.getcurrentEvent(data[0].data)
        this._sharedService.initializeCustomJs();
        this._sharedService.callNormalSlider();
        this._sharedService.callFractionSlider();
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }

  getcurrentEvent(array) {
    this.currentEvent = array?.cards?.find(ele => ele?.ComponentHeaderTitle?.data?.replace(/ /g, "-") == this.slugUrl)
    console.log(this.currentEvent, ' this.currentEvent');
    if(!this.currentEvent) this.router.navigate(['**']);
  }

}
