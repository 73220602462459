import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {
  @Output() hideConsent = new EventEmitter();
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this._sharedService.callLoader()
  }
  // sendRouteObject(name) {
  //   this._sharedService.checkCTALink('', "/legal?tab="+name)
  //   return false; // necessary to ignore href value
  // }

  hideCookieConsent() {
    this.hideConsent.emit('true');
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookieConsent', 'true');
    }
  }
}
