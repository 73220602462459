import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant';
declare var $: any;
@Component({
  selector: 'app-inquiry-careers',
  templateUrl: './inquiry-careers.component.html',
  styleUrls: ['./inquiry-careers.component.css']
})
export class InquiryCareersComponent implements OnInit,OnChanges, AfterViewInit, OnDestroy {
  careersForm: FormGroup;
  inquiryType = 'Careers';
  @Input() selectedRole
  private subscription;
  AgeDropdown
  GenderDropdown
  EducationDropdown
  ExperienceDropdown
  LocationDropdown
  index
  serchText
  filterdCity = []
  filterdState = []
  @Input() selectedBrand;
  errorText = 'Field is required';
  isCaptchaSelected;
  submitted = false;
  generalData;
  generalArrData: any;
  disableBtn;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() AllRoles;
  @Input() captchaSiteKey;
  fileSizeError = "";
  fileTypeError = "";
  fileSizeLimitInBytes = 5242880;
  fileExtensions = ['.pdf', '.doc', '.docx'];
  apiErrorMsg = "";
  grcToken = "XXXXXXXXXXX";
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  constructor(public _sharedService: SharedService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
  }

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.generalData = this.inquiryData.cards[0];
    this.AgeDropdown = this.inquiryData.AgeDropdown;
    this.GenderDropdown = this.inquiryData.GenderDropdown;
    this.EducationDropdown = this.inquiryData.EducationDropdown;
    this.ExperienceDropdown = this.inquiryData.ExperienceDropdown;
    this.LocationDropdown = this.inquiryData.LocationDropdown;
    this.generalArrData = this.generalData.garden;
    this.careersForm = this.formBuilder.group({
      inquiryType: ['Careers'],
      brand: ['JWC'],
      role: [(this.selectedRole), [Validators.required]],
      others: [''],
      jobId: [''],
      nameInput: [(prefilledData.name) ? prefilledData.name : '', Validators.required],
      emailInput: [(prefilledData.email) ? prefilledData.email : '', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo) ? prefilledData.phoneNo : '', [Validators.required, Validators.minLength(10)]],
      urlInput: ['', Validators.pattern(constants.URL_REGEX)],
      age: ['', Validators.required],
      gender: ['', Validators.required],
      qualification: ['', Validators.required],
      experience: ['', Validators.required],
      currentState: ['', Validators.required],
      currentCity: ['', Validators.required],
      currentOrganisation:[''],
      messageInput: [(prefilledData.message) ? prefilledData.message : ''],
      fileInput: ['', Validators.required],
      file: ['', Validators.required],
      tcCheckbox: [false, Validators.requiredTrue]
    });
    this._sharedService.callCustomDropdown()

    if (this.route?.snapshot?.queryParams['jobId']) {
      let jobId = this.route?.snapshot?.queryParams['jobId']
      this.careersForm.patchValue({
        jobId: jobId
      })

    }
  }

  ngOnChanges() {
    this.careersForm?.get('role').setValue(this.selectedRole);
}

  get careersFormControls() { return this.careersForm.controls; }

  sendCareersRouteObj(tab) {
    this.router.navigate(['/legal'], {
      state: { tabName: tab }
    });
    return false;
  }

  setDropdownValue(formControl, value) {
    this.careersForm.get(formControl).setValue(value);
  }

  setDropdownValueRole(role, jobId) {
    this.careersForm.get('role').setValue(role);
    this.careersForm.get('jobId').setValue(jobId);
    this.careersForm.get('others').setValue('');
    this.careersForm.get('others').clearValidators()
    this.careersForm.get('others').updateValueAndValidity()
  }

  OtherRoleSelected() {
    this.setDropdownValue('role','Other');
    this.setDropdownValue('jobId','')
    this.careersForm.get('others').setValidators([Validators.required])
    this.careersForm.get('others').updateValueAndValidity()
  }

  filterLocation(location) {
    if (location == 'state') {
      this.filterdState = this.LocationDropdown?.filter(obj => obj?.state?.toLowerCase().includes(this.serchText?.toLowerCase()))
    } else {
      this.filterdCity = this.LocationDropdown[this.index]?.cities?.filter(obj => obj?.toLowerCase().includes(this.serchText?.toLowerCase()))
    }
  }

  setState(location,state) {
    if (location=='state') {
      this.careersForm.get('currentState').setValue(state)
      this.careersForm.get('currentCity').setValue('')
      this.index = this.LocationDropdown.findIndex(obj => obj.state == state)
      setTimeout(() => {
        this.filterdState = []
      }, 100);
    } else {
      this.careersForm.get('currentCity').setValue(state)
      setTimeout(() => {
        this.filterdCity = []
      }, 100);
    }
  }

  careersFormSubmit(form) {
    this.submitted = true;
    // if (!this.submitted) {
      if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let formData = new FormData();
    formData.append('grcToken', this.grcToken);
    for (const field in this.careersFormControls) {
      formData.append(field, this.careersForm.controls[field].value);
    }
    let careersEveObj = {
      Enquiry_Type: form.value.inquiryType,
      Brand_Name: form.value.brand,
      LinkedIn_URL: form.value.urlInput,
      Organiser_Phone: form.value.countryCode + form.value.phoneNoInput,
      Organiser_Email: form.value.emailInput,
      Organiser_Name: form.value.nameInput
      // Event_Name: 'Enquiry_Forms'
    };
    let careersCTProfile = {
      Organiser_Phone: form.value.countryCode + form.value.phoneNoInput,
      Organiser_Email: form.value.emailInput,
      Organiser_Name: form.value.nameInput
    };
    // formData.append('file' , this.careersForm.get('file').value);
    this._sharedService.submitInquiryForm(formData, careersEveObj, careersCTProfile, true);
  }

  captchaRes($event, flag?) {
    if (!$event) return this.isCaptchaSelected = false;
    if (flag) return console.log('Something went wrong when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  //Attachment 
  handleFileInput(fileObject) {
    // console.log("fileObject", fileObject);
    let fileSizeValid = false;
    let fileTypeValid = false;
    if (fileObject.length > 0) {
      let attachedFile = fileObject[0];
      fileSizeValid = this.isFileSizeValid(attachedFile);
      fileTypeValid = this.isFileTypeValid(attachedFile);
      if (fileSizeValid && fileTypeValid) {
        this.careersForm.patchValue({
          file: attachedFile
        });
        this.careersForm.get('file').updateValueAndValidity();
      } else {
        this.careersForm.patchValue({
          file: ''
        });
      }
    }
  }

  private isFileSizeValid(fileObj): boolean {
    let totalSizeMB = this.fileSizeLimitInBytes / Math.pow(1024, 2);
    if (this.fileSizeLimitInBytes !== -1 && fileObj.size >= this.fileSizeLimitInBytes) {
      this.fileSizeError = "File size limit should be " + Math.round(totalSizeMB) + "MB max";
      return false;
    } else if (fileObj.size == 0) {
      this.fileSizeError = "File is empty";
      return false;
    } else {
      this.fileSizeError = "";
      return true;
    }
  }

  private isFileTypeValid(fileObj): boolean {
    const fileExtn = fileObj.name.split(".").pop();
    if (this.fileExtensions && this.fileExtensions.includes("." + fileExtn.toLowerCase())) {
      this.fileTypeError = "";
      return true;
    }
    this.fileTypeError = "Invalid file type";
    return false;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.careersFormControls.nameInput.value) ? this.careersFormControls.nameInput.value : '',
      email: (this.careersFormControls.emailInput.value) ? this.careersFormControls.emailInput.value : '',
      company: '',
      message: (this.careersFormControls.messageInput.value) ? this.careersFormControls.messageInput.value : '',
      phoneNo: (this.careersFormControls.phoneNoInput.value) ? this.careersFormControls.phoneNoInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message, obj.phoneNo);
  }

  ngAfterViewInit() {

    $(document).on('click','.dropdown--select', function () {
          $(".serchText").focus()
      })

    $(document).on('click', '.dropdown .dropdown--list ul li', function () {
      var getText = $(this).text();
      $(this).parents(".dropdown").find(".dropdown--select").text(getText);
      $(this).parents(".dropdown").removeClass("open");
      $(this).parent().find(".active").removeClass("active");
      $(this).addClass("active");
      $('.serchText').val(null);
    });
  }

}
