import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { MetaService } from './shared/services/meta.service';
import { SharedService } from './shared/services/shared.service';
import { MetaPageKeys } from './shared/shared.constant';
declare function callCleverTap(id): any;
declare let fbq: Function;
declare let gtag: Function;
declare function version(): any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  consentFlag = 'false';
  title = 'JWC_AJ_JwcUI';
  isSticky;
  pageName;
  notification$: Observable<any>;
  notificationPair: Map<string, string>;
  //phase1 live change
  isNewHF;
  isNewHeaderDesk;
  closeNotificationDiv = false;
  browserVersion: boolean;
  closeExpiredNotificationDiv = false;
  isGuestGuidePage = false;
  islandingPage;
  schemaScript: any;
  metaKeys = MetaPageKeys;
  constructor(private router: Router, 
    private titleService: Title, 
    private activatedRoute: ActivatedRoute, 
    private _sharedService: SharedService, 
    private changeDetector: ChangeDetectorRef, 
    public customMetaService: MetaService) {
    this.notificationPair = new Map([
      ['3', '199325'],
      //['4', '199325'],
      ['2', '261839'],
      ['1', 'JWC-HOME-NOTIFICATION']
    ]);
    console.log({checkBrowser: this._sharedService.checkBrowser})
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  ngOnInit() {
    if(this._sharedService.checkBrowser){
    const appTitle = this.titleService.getTitle();
    this.browserVersion = version();
    if (!this.browserVersion) this.router.navigate(['/upgrade-browser']);
    this.consentFlag = (localStorage.getItem('cookieConsent') ? localStorage.getItem('cookieConsent') : 'false');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let fChild = this.activatedRoute.firstChild;
        gtag('config', 'UA-185010494-1', { 'page_path': event.urlAfterRedirects });
        gtag('config', 'AW-662181025');
        fbq('track', 'PageView');
        if (fChild.snapshot.data['gtagConversion']) {
          // Event snippet for Page view_1 conversion page
          if (fChild.snapshot.data['gtagConversion'] == 'page-view') { gtag('event', 'conversion', { 'send_to': 'AW-662181025/R2jjCNLb7ooDEKGp4LsC' }); }
          // Event snippet for Lead conversion page
          else if (fChild.snapshot.data['gtagConversion'] == 'lead') { gtag('event', 'conversion', { 'send_to': 'AW-662181025/FI8WCNyO1MgBEKGp4LsC' }); }
        }
      }
    });
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          const fragment: string = this.activatedRoute.snapshot.fragment;
          const rdata = this._sharedService.getPreviousRouteData();
          if (child.snapshot.data['page'] == 'sign-in') this.getStickyValForSignin();
          else this.isSticky = (child.snapshot.data['sticky']) ? child.snapshot.data['sticky'] : false;
          this.islandingPage = (child.snapshot.data['landingPage']) ? child.snapshot.data['landingPage'] : false;
          // phase1 live changes start
          this.isNewHF = (child.snapshot.data['newHF']) ? child.snapshot.data['newHF'] : false;
          this.isNewHeaderDesk = (child.snapshot.data['newHeaderDesk']) ? child.snapshot.data['newHeaderDesk'] : false;
          // phase1 live changes end
          // if (child.snapshot.data['description']) {
          //   this.metaService.updateTag({ name: 'description', content: child.snapshot.data['description'] })
          // } else {
          //   this.metaService.removeTag("name='description'")
          // }
          // if (child.snapshot.data['keywords']) {
          //   this.metaService.updateTag({ name: 'keywords', content: child.snapshot.data['keywords'] })
          // } else {
          //   this.metaService.removeTag("name='keywords'")
          // }
          // const tags = child.snapshot.data['metatags'];
          // // console.log("tags: ", tags);
          // if (tags) {
          //   for (const tag in tags) {
          //     // console.log("tags[tag]: ", tags[tag]);
          //     // if(tags[tag]="") this.metaService.removeTag("name="+tag);
          //     this.metaService.updateTag({ name: tag, content: tags[tag] });
          //   }
          // }
          if (child.snapshot.data['page']) {
            if (child.snapshot.data['page'] == 'guest-guide') this.isGuestGuidePage = true;
            if (child.snapshot.data['title'] == 'Downloads') {
              if (child.snapshot.params['brand'] == 'jio-world-convention-centre') {
                this.pageName = 'cec'
              } else if (child.snapshot.params['brand'] == 'jio-world-garden') {
                this.pageName = 'garden'
              }
            } else if (child.snapshot.data['page'] == 'sign-in') {
              this.pageName = this._sharedService.getPreviousRouteData();
            } else if (child.snapshot.data['page'] == 'chatbot' || child.snapshot.data['page'] == 'guest-guide' || child.snapshot.data['page'] == 'tickets' || child.snapshot.data['page'] == 'blank') {
              this.pageName = ''
            } else {
              this.pageName = child.snapshot.data['page']
            }
          } else {
            this.pageName = 'home'
          }
          if (child.snapshot.data['title']) {
            if (child.snapshot.data['title'] == 'Convention Centre Venue Detail') {
              switch (child.snapshot.params['room']) {
                case 'convention-halls':
                  //this.metaService.updateTag({ name: 'keywords', content: 'convention halls, concert hall, hybrid events' });
                  //this.metaService.updateTag({ name: 'description', content: "The Jio World Convention Centre has the largest convention hall in India, customised to host concerts & large-scale conferences offering physical & hybrid experiences" });
                  return 'Jio World Centre - Convention Halls for Conferences';
                case 'banquet-halls':
                case 'ballroom':
                  //this.metaService.updateTag({ name: 'keywords', content: "ballroom, banquet hall, wedding hall, marriage hall" });
                  //this.metaService.updateTag({ name: 'description', content: "The Jio World Convention Centre's majestic Lotus Ballroom sets the stage for a variety of social events including grand weddings and glamourous red carpet functions" });
                  return 'Jio World Centre - Ballrooms for Grand Weddings and Red Carpet Events ';
                case 'meeting-rooms':
                 // this.metaService.updateTag({ name: 'keywords', content: "meeting space, meeting rooms, conference centre" });
                  //this.metaService.updateTag({ name: 'description', content: "The Jio World Convention Centre's dynamic meeting rooms are designed for peak performance. These spaces can host large-scale meets, conferences & small private " });
                  return 'Jio World Centre - Meeting Rooms and Business Lounges';
                default:
                  return 'Jio World Centre - Exhibition Halls for Events, Trade Shows and Concerts';
              }
            } else if (child.snapshot.data['title'] == 'CEC Walkthrough') {
              switch (child.snapshot.params['room']) {
                case 'convention-halls':
                  //this.metaService.updateTag({ name: 'keywords', content: "convention halls walkthrough, venue walkthrough" });
                  //this.metaService.updateTag({ name: 'description', content: "Venue Walkthrough - Explore the Jio World Convention Centre & find out more about its spacious and elegant convention halls, also knowns as the Jasmine Halls" });
                  return 'Jio World Centre - Convention Hall Walkthrough';
                case 'banquet-halls':
                case 'banquet-hall':
                  //this.metaService.updateTag({ name: 'keywords', content: "ballroom walkthtrough, venue walkthrough" });
                  //this.metaService.updateTag({ name: 'description', content: "Venue Walkthrough - Explore the Jio World Convention Centre and its majestic Lotus Ballroom designed to host grand weddings and glamorous red carpet events" });
                  return 'Jio World Centre - Ballroom Walkthrough';
                case 'meeting-rooms':
                  //this.metaService.updateTag({ name: 'keywords', content: "meeting room walkthrough, venue walkthrough" });
                  //this.metaService.updateTag({ name: 'description', content: "Venue Walkthrough - Explore the Jio World Convention Centre and its 25 meeting rooms equipped with cutting-edge technology" });
                  return 'Jio World Centre - Meeting Room Walkthrough';
                default:
                  return 'Jio World Centre - Exhibition Hall Walkthrough';
              }
            } else if (child.snapshot.data['title'] == 'Downloads') {
              switch (child.snapshot.params['brand']) {
                case 'jio-world-convention-centre':
                  return 'Jio World Convention Centre - Downloads';
                case 'jio-world-garden':
                  return 'Jio World Garden - Downloads';
              }
            } else {
              if (this.notificationPair.get(child.snapshot.data['notificationId']) !== undefined && ((fragment && !this.closeNotificationDiv) || (!fragment && !this.closeNotificationDiv))) {
                this.notification$ = this._sharedService.getCommonService().getlandingData(this.notificationPair.get(child.snapshot.data['notificationId']))
                // console.log("if", this.notification$)
              } else {
                this.notification$ = null
                // console.log("else", this.notification$)
                // Testing
              }
              return child.snapshot.data['title'];
            }
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        // this.titleService.setTitle(ttl);
      });

    // this.router.events.pipe(
    //   filter((event: any) => {
    //     return event instanceof NavigationEnd;
    //   }),
    //   map((event: any) => {
    //     return event;
    //   })
    // ).subscribe((res) => {
    //   const currentRoute = res.url || '';
    //   const findMetaKey = this.metaKeys.find((item) => currentRoute === item.route);
    //   if (!findMetaKey) return;
    //   this._sharedService.getCommonService().getlandingUrl([findMetaKey.metaKey]).subscribe(
    //     (data: any) => {
    //       if (data.length === 0 || (data.length > 0 && !data[0].data)) return;
    //       const metaObj = data[0].data;
    //       this.customMetaService.updatePageTag('og:title', 'Jio World Centre | Global Business and Cultural Hub | Official Website');
    //       this.customMetaService.updatePageTag('og:site_name', 'Jio World Centre');
    //       this.customMetaService.updatePageTag('og:url', 'https://www.jioworldcentre.com/');
    //       this.customMetaService.updatePageTag('og:type', 'website');
    //       this.customMetaService.updatePageTag('og:description', 'What is Jio World Centre? JWC is a stunning new landmark located in the heart of Mumbai, spread over an area of 18.5 acres. Know more about Jio World Centre and its offerings now!');
    //       this.customMetaService.updatePageTag('og:image', 'https://www.jioworldcentre.com/assets/images/menu/jioworld-convention-centre.png');
    //       if(!metaObj) return;
    //       const metaTitle = metaObj?.MetaTitle?.data || '';
    //       const metaDesc = metaObj?.MetaDescription?.data || '';
    //       const script = metaObj?.SchemaScript?.data || '';
    //       if (metaObj.MetaTitle && metaObj.MetaTitle.data != ''){
    //         this.customMetaService.updatePageTag('title', metaTitle);
    //         this.customMetaService.updatePageTitle(metaTitle);
    //       }
    //       if (metaObj.MetaDescription && metaObj.MetaDescription.data != '')
    //         this.customMetaService.updatePageTag('description', metaDesc);
    //       if (metaObj.CanonicalURL && metaObj.CanonicalURL.data != '')
    //         this.customMetaService.updateCanonicalLink({ rel: 'canonical', href: metaObj.CanonicalURL.data });
    //       if(metaObj.SchemaScript && metaObj.SchemaScript.data != '')  
    //         this.schemaScript = this._sharedService.getSafeHTML(script, false);
    //     })
    // })
    if ((environment.env == "prod")) {
      callCleverTap('WWW-R95-895Z');
      const script = document.createElement('script');
      script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "7j14vk6oqb");`;
      document.head.appendChild(script);
    } else {
      callCleverTap('TEST-ZWW-R95-895Z');
    }

    this._sharedService.getCommonService().getlandingUrl(['GLOBAL-CONFIGURATIONS'])
    .pipe(map((data: any) => data[0]?.data?.cards))
    .subscribe(
      (data: any) => {
       this._sharedService.setConfigData(data); 
    })
  }
  }
  closeNotificationEvent(event) {
    this.closeNotificationDiv = event
    // console.log("this.closeNotificationDiv", this.closeNotificationDiv)
  }
  closeExpiredNotificationEvent(ev) {
    this.closeExpiredNotificationDiv = ev
    // console.log("this.closeExpiredNotificationDiv", this.closeExpiredNotificationDiv)
  }
  getStickyValForSignin() {
    let url = this._sharedService.getPreviousUrl();
    if (url) this.isSticky = url.state.root.firstChild.data.sticky ? url.state.root.firstChild.data.sticky : false;
  }
}
