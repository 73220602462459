<form [formGroup]="logisticsInquiryForm" InvalidControlScroll>
    <div class="row">
      <div class="col-md-6">
        <div class="jwc_form_group">
          <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
            <div class="dropdown--select">{{inquiryType}}</div>
            <div class="dropdown--list" aria-expanded="false" role="list">
              <ul>
                <li tabindex="0" (click)="inquiryTypeValue.emit(item)" *ngFor="let item of inquiryData?.inquiryType">{{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="jwc_form_group">
          <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
            <div class="dropdown--select">{{ selectedBrand }}</div>
            <div class="dropdown--list" aria-expanded="false" role="list">
              <ul>
                <!-- *ngFor="let item of venueCecData?.brand" // removed loop since logistics value is available only for cec brand--> 
                <li tabindex="0" (click)="brandValue.emit(venueCecData?.brand[0].value)">{{venueCecData?.brand[0].label}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="jwc_form_title">
          A few more details and you’ll be all set.
        </div>
      </div>
      <!-- Name -->
      <div class="col-md-6">
        <div class="jwc_form_group">
          <input type="text" name="" value="" placeholder="Name" class="form-control jwc_input"
            formControlName="nameInput" appAllowedInput [alphabetsOnly]="true">
        </div>
        <div *ngIf="(logisticsInquiryFormControls.nameInput.touched || submitted) && logisticsInquiryFormControls.nameInput.errors">
          <div *ngIf="logisticsInquiryFormControls.nameInput.errors.required" class="error-text">{{errorText}}</div>
        </div>
      </div>
      <!-- User -->
      <div class="col-md-12">
        <div class="jwc_form_title">I am</div>
        <div class="row jwc_form_group">
          <div class="col-md" *ngFor="let item of venueCecArrData?.userIdentity; let i = index">
            <div class="custom-control custom-checkbox checkbox jwc_form_group">
                <!-- [checked] = "typeOfHall == item" -->
              <input type="checkbox" class="custom-control-input" id="{{'checked'+(i+1)}}" name="dontmiss" value={{item}} (change)="onUserTypeCheckboxClick('userType', $event)">
              <label class="custom-control-label" for="{{'checked'+(i+1)}}">{{item}}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Event Name -->
      <div class="col-md-6">
        <div class="jwc_form_group">
          <input type="text" name="" value="Event Name" placeholder="Event Name" class="form-control jwc_input" id="" formControlName="eventNameInput">
        </div>
      </div>
      <div class="col-md-6"></div>
      <!--contact number-->
    <div class="col-md-6">
        <div class="jwc_form_group jwc_form_phn">
          <div class="dropdown" role="button" aria-labelledby="dropdown-label" tabindex="0">
            <div class="dropdown--select">+91</div>
            <div class="dropdown--list" aria-expanded="false" role="list">
              <ul>
                <li tabindex="0" (click)="setDropdownValue('countryCode', item)" *ngFor="let item of venueCecArrData?.countryCode">
                  {{item}}</li>
              </ul>
            </div>
          </div>
          <input type="text" name="" value="" placeholder="Phone Number" class="form-control jwc_input" id=""
            formControlName="phoneNoInput" maxlength="10" appAllowedInput [numbersOnly]="true">
        </div>
        <div class="error-text"
          *ngIf="(logisticsInquiryFormControls.phoneNoInput.touched || submitted) && logisticsInquiryFormControls.phoneNoInput.errors">
          Enter 10 digit Mobile Number</div>
      </div>
      <!--email-->
      <div class="col-md-6">
        <div class="jwc_form_group">
          <input type="email" name="" value="" placeholder="Email" class="form-control jwc_input"
            formControlName="emailInput">
        </div>
        <div *ngIf="(logisticsInquiryFormControls.emailInput.touched || submitted) && logisticsInquiryFormControls.emailInput.errors">
          <div *ngIf="logisticsInquiryFormControls.emailInput.errors.required" class="error-text">{{errorText}}</div>
          <div *ngIf="logisticsInquiryFormControls.emailInput.errors.pattern" class="error-text">Email must be a valid email address
          </div>
        </div>
      </div>
  
      <div class="col-md-12">
        <div class="jwc_form_group">
          <textarea name="" class="form-control jwc_textarea" placeholder="Message" cols="30" rows="10"
            formControlName="messageInput"></textarea>
        </div>
        <div *ngIf="(logisticsInquiryFormControls.messageInput.touched || submitted) && logisticsInquiryFormControls.messageInput.errors">
          <div *ngIf="logisticsInquiryFormControls.messageInput.errors.required" class="error-text">{{errorText}}</div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="custom-control custom-checkbox checkbox jwc_form_group">
          <input type="checkbox" class="custom-control-input" id="dontmiss" name="dontmiss" formControlName="tcCheckbox">
          <label class="custom-control-label" for="dontmiss"><div>By ticking the box, you accept our <a  href="{{'/legal?tab=terms'}}" (click)="_sharedService.checkCTALink($event, staticUrlForImgNew + '/legal?tab=terms', '_blank', 'External')">Terms &amp; Conditions</a></div></label>
        </div>
        <div class="error-text" *ngIf="submitted && logisticsInquiryFormControls.tcCheckbox.errors">{{errorText}}</div>
      </div>
      <div class="col-md-12 captcha">
        <re-captcha (resolved)="captchaRes($event)" [siteKey]="captchaSiteKey"
            (error)="captchaRes($event, true)" errorMode="handled"></re-captcha>
        <div class="error-text" style="margin-top:5px" *ngIf="submitted && !isCaptchaSelected">{{errorText}}</div>
      </div>
    </div>
    <div class="jwc_form_group--btn">
      <button type = "submit" [ngClass]="{'disabled':disableBtn}" [disabled]="disableBtn" class="cta-prim color-gold" (click)="logisticsCecSubmit(logisticsInquiryForm)"><span
          class="cta-prim-txt">Submit</span></button>
    </div>
    <div class="error-text" style="margin-top:5px" *ngIf="apiErrorMsg">{{apiErrorMsg}}</div>
  </form>