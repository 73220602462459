import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClevertapService {

  constructor() { }
  recordSubscriptionEvent(eventName, eventObj) {
    console.log(eventName, eventObj);   
    return (<any>window).clevertap.event.push(eventName, eventObj);
  }
  createCTUserLogin(profile) {
    return (<any>window).clevertap.onUserLogin.push({ Site: profile });
  }
}
