import { Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MetaPageKeys } from "../../shared.constant";
import { MetaService } from "../meta.service";
import { SharedService } from "../shared.service";


@Injectable({
    providedIn: 'root'
})
export class MetaResolver implements Resolve<any> {
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
    constructor(private _sharedService: SharedService, 
        private customMetaService: MetaService,
        private router: Router,
        @Inject(Location) private location: any) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const platFormLocation = this.location._platformLocation;
        const currRoute = platFormLocation.hostname.includes('webcache') ? '/' : platFormLocation.pathname;
        console.log({currRoute});
        // const currRoute = route.routeConfig.path;
        //this.customMetaService.updatePageTag('og:title', 'test ssr meta tags from guard');
      const findMetaKey = MetaPageKeys.find((item) => item.route === currRoute);
      console.log({findMetaKey});
      if (!findMetaKey) return;
      return this._sharedService.getCommonService()
      .getlandingUrl([findMetaKey.metaKey])
      .pipe(map(
        (data: any) => {
          console.log({data});
          if (!data?.[0]?.data) return;
          const metaObj = data[0].data;
          // console.log({metaObj});
          // this.customMetaService.updatePageTag('og:title', 'Jio World Centre | Global Business and Cultural Hub | Official Website');
          // this.customMetaService.updatePageTag('og:site_name', 'Jio World Centre');
          // this.customMetaService.updatePageTag('og:url', 'https://www.jioworldcentre.com/');
          // this.customMetaService.updatePageTag('og:type', 'website');
          // this.customMetaService.updatePageTag('og:description', 'What is Jio World Centre? JWC is a stunning new landmark located in the heart of Mumbai, spread over an area of 18.5 acres. Know more about Jio World Centre and its offerings now!');
          // this.customMetaService.updatePageTag('og:image', 'https://www.jioworldcentre.com/assets/images/menu/jioworld-convention-centre.png');
          if(!metaObj) return;
          // const metaTitle = metaObj?.MetaTitle?.data || '';
          // const metaDesc = metaObj?.MetaDescription?.data || '';
          // const script = metaObj?.SchemaScript?.data || '';
          // if (metaObj.MetaTitle && metaObj.MetaTitle.data != ''){
          //   console.log({metaTitle});
          //   this.customMetaService.updatePageTag('title', metaTitle);
          //   this.customMetaService.updatePageTag('og:title', metaTitle);
          //   this.customMetaService.updatePageTitle(metaTitle);
          // }
          // if (metaObj.MetaDescription && metaObj.MetaDescription.data != '')
          //   this.customMetaService.updatePageTag('description', metaDesc);
          //   this.customMetaService.updatePageTag('og:description', metaDesc);
          // if (metaObj.CanonicalURL && metaObj.CanonicalURL.data != '')
          //   this.customMetaService.updateCanonicalLink({ rel: 'canonical', href: metaObj.CanonicalURL.data });
          // // if(metaObj.SchemaScript && metaObj.SchemaScript.data != '')  
          //   //this.schemaScript = this._sharedService.getSafeHTML(script, false);
          const metaLogo = metaObj?.EventMetaLogo?.image?.contentUrl ||  metaObj?.MetaLogo?.image?.contentUrl; 
          // this.customMetaService.updatePageTag('image', metaLogo);
          // this.customMetaService.updatePageTag('og:image', metaLogo);
          this.customMetaService.updatePageMeta({
            metaTitle: metaObj?.MetaTitle?.data,
            metaDesc: metaObj?.MetaDescription?.data,
            metaLogo: metaLogo,
            script: metaObj?.SchemaScript?.data,
            canonicalUrl: metaObj?.CanonicalURL?.data,
          });  
          return true;
        })
      )
    }
}