<div class="header jwc_navbar_brand" [ngClass]="isSticky ? 'jwc_sticky_navbar' : 'jwc_fixed_navbar'">
  <!-- desktop navbar starts -->
  <div class="jwc_navbar d-none d-lg-block">
    <div class="jwc_navbar_inner">
      <ul class="jwc_navbar_list" role="navigation">
        <!-- <li class="nav-item" *ngIf="!isSticky">
        <a href="javascript:void(0)" class="nav-link" routerLink="/"><img src="./assets/images/logo-gold.svg" class="img-fluid" alt=""></a>
      </li> -->
        <li class="nav-item dropdown jwc_dropdown_hover position-static">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
            Explore Our World
          </a>
          <div class="dropdown-menu w-100 jwc_dropdown_hover_content" aria-labelledby="navbarDropdown">
            <div class="jwc_navbar_dropdown">
              <div class="jwc_navbar_dropdown_inner">
                <div class="row">
                  <div class="col-md-3">
                    <ul class="jwc_navbar_dropdown_list jwc_color_hover no_column" id="venues_list"
                      style="column-count: 1">
                      <li><a href="javascript:void(0)" routerLink="/dhirubhai-ambani-square"
                          [attr.style]="('--data-color-hover:#A67D54') | safe: 'style'" (click)="callHeaderCTEvent()"
                          data-img="./assets/images/menu/dhirubhai-ambani-square-header-image.jpg">Dhirubhai Ambani
                          Square</a>
                      </li>
                      <li><a href="javascript:void(0)" routerLink="/jio-world-convention-centre"
                          [attr.style]="('--data-color-hover:#1E478E') | safe: 'style'" (click)="callHeaderCTEvent()"
                          data-img="./assets/images/menu/jioworld-convention-centre.png">Jio World Convention
                          Centre​</a></li>
                      <li><a href="{{'https://jioworlddrive.com/'}}" (click)="callHeaderCTEvent('https://jioworlddrive.com/'); _sharedService.checkCTALink($event, 'https://jioworlddrive.com/', '_blank', 'External')" [attr.style]="('--data-color-hover:#A67D54') | safe: 'style'" data-img="./assets/images/menu/JioWorld-Drive​.jpg">Jio World Drive</a></li>
                      <!-- <li><a href="javascript:void(0)" routerLink="/the-cultural-centre"
                          [attr.style]="('--data-color-hover:#BA3F96') | safe: 'style'" (click)="callHeaderCTEvent()"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">The Cultural Centre</a>
                      </li> -->
                      <li><a href="javascript:void(0)" routerLink="/jio-world-garden"
                          [attr.style]="('--data-color-hover:#6CC24A') | safe: 'style'" (click)="callHeaderCTEvent()"
                          data-img="./assets/images/menu/jioworld-garden.jpg">Jio World
                          Garden​</a></li>
                      <li><a href="{{tccUrl}}" (click)="callHeaderCTEvent(tccUrl); _sharedService.checkCTALink($event, tccUrl, '_blank', 'External')" 
                        [attr.style]="('--data-color-hover:#BA3F96; color:#4b4847') | safe: 'style'"
                        data-img="./assets/images/menu/the-cultural-centre-header-image-new.jpg">Nita Mukesh Ambani Cultural Centre</a>
                      </li>
                      <li><a href="{{'https://www.jioworldplaza.com/'}}" (click)="callHeaderCTEvent('https://www.jioworldplaza.com/'); _sharedService.checkCTALink($event, 'https://www.jioworldplaza.com/', '_blank', 'External')" 
                        [attr.style]="('--data-color-hover:#CE0037') | safe: 'style'"
                        data-img="./assets/images/menu/JioWorld-Plaza.jpg">Jio World Plaza​</a>
                      </li>
                      <li><a href="{{'https://www.bayclub.in/'}}" (click)="callHeaderCTEvent('https://www.bayclub.in/'); _sharedService.checkCTALink($event, 'https://www.bayclub.in/', '_blank', 'External')"
                        [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'" data-img="./assets/images/menu/Bay-Club.jpg">Bay Club</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <ul class="jwc_navbar_dropdown_list jwc_color_hover no_column coming_soon" id="venues_list">
                      <li class="subheader">COMING SOON</li>
                      <!-- <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/JioWorld-Drive​.jpg">Jio World Drive​</a></li> -->
                     
                      <!-- <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/Bay-Club.jpg">Bay Club</a></li> -->
                      <!-- <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/JioWorld-Plaza.jpg">Jio World Plaza​</a></li> -->
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/JioWorld-Residences​.jpg">Jio World Residences​</a>
                      </li>
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/The-Clubhouse.jpg">The Clubhouse </a></li>
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/jioworld-offices.png">Jio World Offices</a></li>
                    </ul>
                  </div>
                  <div class="col-md-6 no-pad text-right float-righ">
                    <div class="jwc_navbar_dropdown_list--img venues_list">
                      <img src="./assets/images/menu/jioworld-convention-centre.png" class="img-fluid" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/health-and-covid-safety-measures" (click)="callHeaderCTEvent()">
            COVID Safety Guide
          </a>
        </li> -->
        <!-- <li class="nav-item dropdown position-static">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-display="static" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'">
          WHAT’S ON
        </a>
        <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown">
          <div class="jwc_navbar_dropdown">
            <div class="jwc_navbar_dropdown_inner">
              <div class="row">
                <div class="col-md-7">
                  <ul class="jwc_navbar_dropdown_list" id="whatson_list">
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner.jpg">The Cultural
                        Centre</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Dhirubhai Ambani Square</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner.jpg">Jio World Convention Centre​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Jio World Garden​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner.jpg">Jio World Plaza​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Jio World Drive​</a></li>
                  </ul>
                </div>
                <div class="col-md-5 no-pad text-right">
                  <div class="jwc_navbar_dropdown_list--img whatson_list">
                    <img src="./assets/images/menu/menu-banner.jpg" class="img-fluid" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown jwc_dropdown_hover position-static">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-display="static" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'">
          VISIT &amp; GUIDE
        </a>
        <div class="dropdown-menu w-100 jwc_dropdown_hover_content" aria-labelledby="navbarDropdown">
          <div class="jwc_navbar_dropdown">
            <div class="jwc_navbar_dropdown_inner">
              <div class="row">
                <div class="col-md-7">
                  <ul class="jwc_navbar_dropdown_list jwc_color_hover" id="guide_list">
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Dhirubhai Ambani Square</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner.jpg">Jio World Convention Centre​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Jio World Garden​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner.jpg">Jio World Plaza​</a></li>
                    <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'" data-img="./assets/images/menu/menu-banner2.jpg">Jio World Drive​</a></li>
                    <li><a href="javascript:void(0)" routerLink="/jioworld-faqs" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"
                      data-img="./assets/images/menu/menu-banner2.jpg">FAQs</a></li>
                  </ul>
                </div>
                <div class="col-md-5 no-pad text-right">
                  <div class="jwc_navbar_dropdown_list--img guide_list">
                    <img src="./assets/images/menu/menu-banner.jpg" class="img-fluid" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li> -->
      </ul>

      <div class="jwc_navbar--logo" *ngIf="isSticky">
        <a routerLink="/"><img src="./assets/images/logo-navbar-jwc.svg" class="img-fluid" alt="Jio World centre Logo"></a>
      </div>
      <div class="jwc_navbar_jwc--logo" *ngIf="!isSticky">
        <a routerLink="/"><img src="./assets/images/logo-navbar-jwc.svg" class="img-fluid" alt="Jio World centre Logo"></a>
      </div>

      <ul class="jwc_navbar_list justify-content-end" role="navigation">
        <!-- <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"><span class="icon-user"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"><span class="icon-search"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)" [attr.style]="('--data-color-hover: ' + colorList?.headerDataHoverColor) | safe: 'style'"><span class="icon-ticket"></span>BOOK TICKETS</a>
      </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/enquiries" (click)="callHeaderCTEvent()">
            Enquiries
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/get-directions" (click)="callHeaderCTEvent()">
            VISIT US
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/careers" (click)="callHeaderCTEvent()">
            Careers
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle jwc_hamburger" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" aria-label="dropdown menu">
            <span role="menu"></span>
            <span role="menu"></span>
            <span role="menu"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <div class="jwc_navbar_dropdown">
              <div class="jwc_navbar_dropdown_inner">
                <ul class="jwc_navbar_dropdown_list no_column text-right">
                  <li><a routerLink="/about" (click)="callHeaderCTEvent()">About</a></li>
                  <!-- <li><a 
                  href="javascript:void(0)" routerLink="/press-and-media">Press &amp; Media</a></li> -->
                  <li><a href="javascript:void(0)" routerLink="/frequently-asked-questions"
                      (click)="callHeaderCTEvent()">FAQS</a></li>
                  <li><a href="javascript:void(0)" routerLink="/contact-us" (click)="callHeaderCTEvent()">Contact</a>
                  <!-- <li><a href="javascript:void(0)" routerLink="/careers" (click)="callHeaderCTEvent()">Careers</a>
                  </li> -->
                  <li><a href="javascript:void(0)" routerLink="/newsroom" (click)="callHeaderCTEvent()">Media Room</a>
                  </li>
                  <li><a href="javascript:void(0)" routerLink="/get-directions" (click)="callHeaderCTEvent()">Get
                      Directions</a>
                  </li>
                  <li *ngIf="showExhibitorLogin"><a href="javascript:void(0)" routerLink="/logistics-services" (click)="callHeaderCTEvent()">Logistics Services</a>
                  </li>
                  <!-- <li><a href="javascript:void(0)" routerLink="/health-and-covid-safety-measures" (click)="callHeaderCTEvent()">COVID Safety Guide</a>
                </li> -->
                  <!-- <li *ngIf="!isUserLoggedIn"><a href="javascript:void(0)" routerLink="/sign-in" (click)="callHeaderCTEvent()">Sign In/ Sign
                      Up</a>
                  </li>
                  <li *ngIf="isUserLoggedIn"><a href="javascript:void(0)" (click)="logout()">{{userDetails?.firstName + " " + userDetails?.lastName}} Logout</a>
                </li> -->
                  <!-- <li *ngIf="showLoginBtn && !isUserLoggedIn"><a href="javascript:void(0)" data-toggle="modal"
                      data-target="#loginModal">Login</a></li>
                  <li *ngIf="showLoginBtn && isUserLoggedIn"><a href="javascript:void(0)" data-toggle="modal"
                      (click)="loginScreen = 'registration'" data-target="#loginModal">View Profile</a></li>
                  <li *ngIf="showLoginBtn && isUserLoggedIn"><a href="javascript:void(0)" (click)="logout()">Logout</a> -->
                  <!-- </li> -->
                  <li class="jwc_social_links">
                    
                    <a href="{{'https://www .instagram.com/jioworldcentre/'}}" aria-label="Social icons" (click)="callHeaderCTEvent('https://www.instagram.com/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.instagram.com/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.081 21.116">
                      <g id="bxl-instagram-alt" transform="translate(-2.997 -2.981)">
                        <path id="Path_11873" data-name="Path 11873"
                          d="M24.01,9.214a7.644,7.644,0,0,0-.491-2.594,5.4,5.4,0,0,0-3.082-3.082,7.733,7.733,0,0,0-2.559-.492C16.752,3,16.4,2.981,13.536,2.981s-3.225,0-4.343.064a7.733,7.733,0,0,0-2.558.492A5.393,5.393,0,0,0,3.553,6.619a7.672,7.672,0,0,0-.491,2.558C3.012,10.3,3,10.662,3,13.52s0,3.224.066,4.343a7.721,7.721,0,0,0,.491,2.56A5.4,5.4,0,0,0,6.637,23.5a7.708,7.708,0,0,0,2.558.527c1.127.05,1.484.066,4.343.066s3.225,0,4.343-.066a7.715,7.715,0,0,0,2.559-.491,5.4,5.4,0,0,0,3.082-3.082,7.71,7.71,0,0,0,.491-2.56c.05-1.126.066-1.483.066-4.343S24.076,10.334,24.01,9.214ZM13.529,18.926a5.412,5.412,0,1,1,5.412-5.412A5.411,5.411,0,0,1,13.529,18.926Zm5.627-9.762A1.262,1.262,0,1,1,20.417,7.9,1.261,1.261,0,0,1,19.157,9.164Z"
                          fill="#4b4847" />
                        <circle id="Ellipse_4224" data-name="Ellipse 4224" cx="3.515" cy="3.515" r="3.515"
                          transform="translate(10.014 9.999)" fill="#4b4847" />
                      </g>
                    </svg>
                    </a>
                    <a href="{{'https://www.facebook.com/JioWorldCentreMumbai'}}"  aria-label="Social icons" (click)="callHeaderCTEvent('https://www.facebook.com/JioWorldCentreMumbai'); _sharedService.checkCTALink($event, 'https://www.facebook.com/JioWorldCentreMumbai', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.963 20.993">
                        <g id="Facebook" transform="translate(-5.254)">
                          <path id="Path_1" data-name="Path 1"
                            d="M87.114,20.993V11.429H90.38L90.846,7.7H87.114V5.365c0-1.05.35-1.866,1.866-1.866h1.983V.117C90.5.117,89.33,0,88.047,0a4.5,4.5,0,0,0-4.782,4.9V7.7H80v3.732h3.266v9.563Z"
                            transform="translate(-74.746 0)" fill="#4b4847" fill-rule="evenodd" />
                        </g>
                      </svg>
                    </a>
                    <!-- <a href="{{'https://twitter.com/'}}" (click)="callHeaderCTEvent('https://twitter.com/'); _sharedService.checkCTALink($event, 'https://twitter.com/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.991 19.459">
                        <g id="Twitter" transform="translate(0 -3.002)">
                          <path id="Path_2" data-name="Path 2"
                            d="M45.6,21.46A13.87,13.87,0,0,0,59.592,7.465V6.8a10.834,10.834,0,0,0,2.4-2.532,11.064,11.064,0,0,1-2.8.8A5.186,5.186,0,0,0,61.325,2.4a12.225,12.225,0,0,1-3.066,1.2,4.76,4.76,0,0,0-3.6-1.6,5.008,5.008,0,0,0-4.932,4.932A2.6,2.6,0,0,0,49.862,8a13.78,13.78,0,0,1-10.13-5.2,5.1,5.1,0,0,0-.666,2.532A5.3,5.3,0,0,0,41.2,9.464,4.493,4.493,0,0,1,38.933,8.8h0a4.871,4.871,0,0,0,4,4.8,4.109,4.109,0,0,1-1.333.133,2.268,2.268,0,0,1-.933-.133,5.049,5.049,0,0,0,4.665,3.465A10.063,10.063,0,0,1,39.2,19.194,3.69,3.69,0,0,1,38,19.06a12.581,12.581,0,0,0,7.6,2.4"
                            transform="translate(-38 1.003)" fill="#4b4847" fill-rule="evenodd" />
                        </g>
                      </svg>
                    </a> -->
                    <a href="{{'https://www.linkedin.com/company/jioworldcentre/'}}"
                       aria-label="Social icons" (click)="callHeaderCTEvent('https://www.linkedin.com/company/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.linkedin.com/company/jioworldcentre/', '_blank', 'External')"
                      class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.186 21.186">
                        <path id="Linkedin"
                          d="M16.8,21.186V14.307c0-1.644-.025-3.755-2.279-3.755-2.287,0-2.629,1.792-2.629,3.632v7H7.506V7.034h4.2V8.973h.057a4.612,4.612,0,0,1,4.157-2.282c4.443,0,5.268,2.928,5.268,6.74v7.754Zm-12.064,0H.343V7.034H4.737V21.185h0ZM7.5,7.034h.008ZM0,2.552A2.548,2.548,0,1,1,2.548,5.1,2.553,2.553,0,0,1,0,2.552Z"
                          fill="#4b4847"></path>
                      </svg>
                    </a>
                    <a href="{{'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'}}"  aria-label="Social icons" (click)="callHeaderCTEvent('https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'); _sharedService.checkCTALink($event, 'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg', '_blank', 'External')" class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.676 22.162">
                        <path id="Youtube" d="M13.338,21.162h0c-.069,0-6.97-.051-9.061-.288-.112-.027-.249-.047-.408-.07a3.709,3.709,0,0,1-2.536-1.228A7.333,7.333,0,0,1,.276,16.593c0-.024-.276-2.435-.276-4.857V9.474c0-2.42.273-4.881.276-4.906A6.667,6.667,0,0,1,1.334,1.587,3.6,3.6,0,0,1,3.921.344h.005L4,.337C7.683.052,13.282,0,13.338,0s5.655.052,9.337.337L22.8.347a3.367,3.367,0,0,1,2.541,1.239A7.333,7.333,0,0,1,26.4,4.569c0,.025.276,2.484.276,4.906v2.261c0,2.373-.273,4.833-.276,4.857a7.335,7.335,0,0,1-1.058,2.982,3.6,3.6,0,0,1-2.588,1.243h-.005l-.074.007C18.992,21.158,13.394,21.162,13.338,21.162ZM10.118,5.723V16.16l8.739-5.1Z" transform="translate(0.5 0.5)" fill="#4b4847" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- desktop navbar ends -->
  <!-- mobile navbar starts -->
  <!-- [ngClass]="{'jwc_navbar_brand': !isSticky}" -->
  <div class="jwc_navbar d-block d-lg-none">
    <div class="jwc_navbar_mobile">
      <button class="navbar-toggler jwc_hamburger" id="jwc_mob_hamburger" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span role="menu"></span>
        <span role="menu"></span>
        <span role="menu"></span>
      </button>

      <div class="jwc_navbar--logo text-center" *ngIf="pageName">
        <a routerLink="{{(routePath.get(pageName))?routePath.get(pageName): '/'}}"><img
            src="./assets/images/{{(logoName.get(pageName))?logoName.get(pageName): 'logo-navbar-jwc'}}.svg"
            [ngClass]="pageName === 'tcc' ? 'height-50' : 'img-fluid'" alt="{{(imgAltTxt.get(pageName))?imgAltTxt.get(pageName): 'Jio World centre Logo'}}"></a>
      </div>

      <ul class="jwc_navbar_list justify-content-end" role="navigation">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/get-directions"><span
              class="icon-location"></span></a>
        </li>
      </ul>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="jwc_mobile_home">
          Go to <a routerLink="/" (click)="callHeaderCTEvent();closeHamMenu()"><img
              src="./assets/images/logo-gold.svg" class="img-fluid" alt=""></a>
        </div>

        <div class="jwc_mobile_menu_scroll">
          <ul class="jwc_navbar_list" role="navigation">
            <li class="nav-item dropdown position-static">
              <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" data-display="static">
                Explore Our World
              </a>
              <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown">
                <div class="jwc_navbar_dropdown">
                  <div class="jwc_navbar_dropdown_inner">
                    <div class="jwc_navbar_list_back">
                      <img src="./assets/images/grey-arrow.svg" class="img-fluid" alt="">
                      Back to
                      <span>Main menu</span>
                    </div>
                    <ul class="jwc_navbar_dropdown_list no_column">
                      <li><a href="javascript:void(0)" routerLink="/dhirubhai-ambani-square"
                          [attr.style]="('--data-color-hover:#A67D54') | safe: 'style'"
                          (click)="callHeaderCTEvent();closeHamMenu()"
                          data-img="./assets/images/menu/dhirubhai-ambani-square-header-image.jpg">Dhirubhai Ambani
                          Square</a>
                      </li>
                      <li><a href="javascript:void(0)" routerLink="/jio-world-convention-centre"
                          (click)="callHeaderCTEvent();closeHamMenu()"
                          data-img="./assets/images/menu/jioworld-convention-centre.png">Jio World Convention
                          Centre​</a></li>
                      <li><a href="javascript:void(0)" (click)="callHeaderCTEvent('https://jioworlddrive.com/'); _sharedService.checkCTALink($event, 'https://jioworlddrive.com/', '_blank', 'External'); closeHamMenu()"
                          data-img="./assets/images/menu/JioWorld-Drive​.jpg">Jio World Drive</a>
                      </li>
                      <!-- <li><a href="javascript:void(0)" routerLink="/the-cultural-centre"
                          (click)="callHeaderCTEvent();closeHamMenu()"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">The Cultural Centre</a>
                      </li> -->
                      <li><a href="javascript:void(0)" routerLink="/jio-world-garden"
                          (click)="callHeaderCTEvent();closeHamMenu()"
                          data-img="./assets/images/menu/jioworld-garden.jpg">Jio World Garden​</a></li>

                      <li><a href="javascript:void(0)" (click)="callHeaderCTEvent(tccUrl); _sharedService.checkCTALink($event, tccUrl, '_blank', 'External'); closeHamMenu()" 
                        [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                        data-img="./assets/images/menu/the-cultural-centre-header-image-new.jpg">Nita Mukesh Ambani Cultural Centre</a>
                      </li>
                       <li><a href="javascript:void(0)" (click)="callHeaderCTEvent('https://www.jioworldplaza.com/'); _sharedService.checkCTALink($event, 'https://www.jioworldplaza.com/', '_blank', 'External'); closeHamMenu()" 
                        [attr.style]="('--data-color-hover:#CE0037') | safe: 'style'"
                        data-img="./assets/images/menu/JioWorld-Plaza.jpg">Jio World Plaza​</a>
                      </li>
                      <li><a href="javascript:void(0)" (click)="callHeaderCTEvent('https://www.bayclub.in/'); _sharedService.checkCTALink($event, 'https://www.bayclub.in/', '_blank', 'External'); closeHamMenu()"
                        [attr.style]="('--data-color-hover:#4b4847') | safe: 'style'" data-img="./assets/images/menu/Bay-Club.jpg">Bay Club</a>
                      </li>
                      </ul>
                    <ul class="jwc_navbar_dropdown_list no_column coming_soon">
                      <li class="subheader">COMING SOON</li>
                      <!-- <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/jioworld-convention-centre.png">Jio World Drive​</a></li> -->
                      <!-- <li><a href="{{tccUrl}}" target="_blank"
                          (click)="callHeaderCTEvent();closeHamMenu()" [attr.style]="('--data-color-hover:#A6A6A6;color:#4b4847') | safe: 'style'"
                          data-img="./assets/images/menu/the-cultural-centre-header-image-new.jpg">Nita Mukesh Ambani Cultural Centre</a>
                      </li>
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/jioworld-garden.jpg">Bay Club</a></li> -->
                      <!-- <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">Jio World Plaza​</a></li> -->
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">Jio World Residences​</a>
                      </li>
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">The Clubhouse </a></li>
                      <li><a href="javascript:void(0)" [attr.style]="('--data-color-hover:#A6A6A6') | safe: 'style'"
                          data-img="./assets/images/menu/the-cultural-centre-header-image.jpg">Jio World Offices</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li class="nav-item dropdown position-static">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              WHAT’S ON
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown">
              <div class="jwc_navbar_dropdown">
                <div class="jwc_navbar_dropdown_inner">
                  <div class="jwc_navbar_list_back">
                    <img src="./assets/images/grey-arrow.svg" class="img-fluid" alt="">
                    Back to
                    <span>WHAT’S ON</span>
                  </div>
                  <ul class="jwc_navbar_dropdown_list no_column">
                    <li><a href="javascript:void(0)">The Cultural
                        Centre</a></li>
                    <li><a href="javascript:void(0)">Dhirubhai Ambani Square</a></li>
                    <li><a href="javascript:void(0)">Jio World Convention Centre​</a>
                    </li>
                    <li><a href="javascript:void(0)">Jio World Garden​</a></li>
                    <li><a href="javascript:void(0)">Jio World Plaza​</a></li>
                    <li><a href="javascript:void(0)">Jio World Drive​</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown position-static">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              VISIT &amp; GUIDE
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="navbarDropdown">
              <div class="jwc_navbar_dropdown">
                <div class="jwc_navbar_dropdown_inner">
                  <div class="jwc_navbar_list_back">
                    <img src="./assets/images/grey-arrow.svg" class="img-fluid" alt="">
                    Back to
                    <span>VISIT &amp; GUIDE</span>
                  </div>
                  <ul class="jwc_navbar_dropdown_list no_column">
                    <li><a href="javascript:void(0)">Dhirubhai Ambani Square</a></li>
                    <li><a href="javascript:void(0)">Jio World Convention Centre​</a></li>
                    <li><a href="javascript:void(0)">Jio World Garden​</a></li>
                    <li><a href="javascript:void(0)">Jio World Plaza​</a></li>
                    <li><a href="javascript:void(0)">Jio World Drive​</a></li>
                    <li><a href="javascript:void(0)" routerLink="/jioworld-faqs">FAQs</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </li> -->
          </ul>

          <ul class="jwc_navbar_dropdown_list no_column font-light">
            <li><a href="javascript:void(0)" routerLink="/about" (click)="callHeaderCTEvent();closeHamMenu()">About</a>
            </li>
            <!-- <li><a href="javascript:void(0)" routerLink="/press-and-media">Press &amp; Media</a></li> -->
            <li><a href="javascript:void(0)" routerLink="/careers"
                (click)="callHeaderCTEvent();closeHamMenu()">Careers</a></li>    
                <li><a href="javascript:void(0)" routerLink="/enquiries"
                  (click)="callHeaderCTEvent();closeHamMenu()">Enquiries</a></li>            
                  <li><a href="javascript:void(0)" routerLink="/frequently-asked-questions"
                (click)="callHeaderCTEvent();closeHamMenu()">FAQS</a></li>
                <li><a href="javascript:void(0)" routerLink="/contact-us"
                  (click)="callHeaderCTEvent();closeHamMenu()">Contact</a></li>
                  <li><a href="javascript:void(0)" routerLink="/newsroom" 
                    (click)="callHeaderCTEvent();closeHamMenu()">Media Room</a>
                  </li>
                  <li><a href="javascript:void(0)" routerLink="/get-directions"
                    (click)="callHeaderCTEvent();closeHamMenu()">Get
                    Directions</a>
                  </li>
                  <li *ngIf="showExhibitorLogin"><a href="javascript:void(0)" routerLink="/logistics-services" (click)="callHeaderCTEvent();closeHamMenu()">Logistics Services</a>
                  </li>
                  <!-- <li><a href="javascript:void(0)" routerLink="/health-and-covid-safety-measures"
                      (click)="callHeaderCTEvent();closeHamMenu()">COVID Safety Guide</a></li> -->
            <!-- <li *ngIf="!isUserLoggedIn"><a href="javascript:void(0)" routerLink="/sign-in" (click)="callHeaderCTEvent();closeHamMenu()">Sign In/
                Sign Up</a>
            </li>
            <li *ngIf="isUserLoggedIn"><a href="javascript:void(0)" (click)="logout();closeHamMenu()">{{userDetails?.firstName + " " + userDetails?.lastName}} Logout</a>
            </li> -->
            <!-- <li *ngIf="showLoginBtn && !isUserLoggedIn"><a href="javascript:void(0)" (click)="closeHamMenu()"
                data-toggle="modal" data-target="#loginModal">Login</a></li>
            <li *ngIf="showLoginBtn && isUserLoggedIn"><a href="javascript:void(0)" data-toggle="modal"
                (click)="loginScreen = 'registration'; closeHamMenu()" data-target="#loginModal">View Profile</a></li>
            <li *ngIf="showLoginBtn && isUserLoggedIn"><a href="javascript:void(0)"
                (click)="logout(); closeHamMenu()">Logout</a></li> -->
            <!-- <li><a href="javascript:void(0)">fEEDBACK</a></li> -->
            <li class="jwc_social_links">              
              <a href="{{'https://www.instagram.com/jioworldcentre/'}}" aria-label="Social icons" (click)="callHeaderCTEvent('https://www.instagram.com/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.instagram.com/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.081 21.116">
                <g id="bxl-instagram-alt" transform="translate(-2.997 -2.981)">
                  <path id="Path_11873" data-name="Path 11873"
                    d="M24.01,9.214a7.644,7.644,0,0,0-.491-2.594,5.4,5.4,0,0,0-3.082-3.082,7.733,7.733,0,0,0-2.559-.492C16.752,3,16.4,2.981,13.536,2.981s-3.225,0-4.343.064a7.733,7.733,0,0,0-2.558.492A5.393,5.393,0,0,0,3.553,6.619a7.672,7.672,0,0,0-.491,2.558C3.012,10.3,3,10.662,3,13.52s0,3.224.066,4.343a7.721,7.721,0,0,0,.491,2.56A5.4,5.4,0,0,0,6.637,23.5a7.708,7.708,0,0,0,2.558.527c1.127.05,1.484.066,4.343.066s3.225,0,4.343-.066a7.715,7.715,0,0,0,2.559-.491,5.4,5.4,0,0,0,3.082-3.082,7.71,7.71,0,0,0,.491-2.56c.05-1.126.066-1.483.066-4.343S24.076,10.334,24.01,9.214ZM13.529,18.926a5.412,5.412,0,1,1,5.412-5.412A5.411,5.411,0,0,1,13.529,18.926Zm5.627-9.762A1.262,1.262,0,1,1,20.417,7.9,1.261,1.261,0,0,1,19.157,9.164Z"
                    fill="#4b4847" />
                  <circle id="Ellipse_4224" data-name="Ellipse 4224" cx="3.515" cy="3.515" r="3.515"
                    transform="translate(10.014 9.999)" fill="#4b4847" />
                </g>
              </svg>
              </a>
              <a href="{{'https://www.facebook.com/JioWorldCentreMumbai'}}" aria-label="Social icons" (click)="callHeaderCTEvent('https://www.facebook.com/JioWorldCentreMumbai'); _sharedService.checkCTALink($event, 'https://www.facebook.com/JioWorldCentreMumbai', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.963 20.993">
                  <g id="Facebook" transform="translate(-5.254)">
                    <path id="Path_1" data-name="Path 1"
                      d="M87.114,20.993V11.429H90.38L90.846,7.7H87.114V5.365c0-1.05.35-1.866,1.866-1.866h1.983V.117C90.5.117,89.33,0,88.047,0a4.5,4.5,0,0,0-4.782,4.9V7.7H80v3.732h3.266v9.563Z"
                      transform="translate(-74.746 0)" fill="#4b4847" fill-rule="evenodd" />
                  </g>
                </svg>
              </a>
              <!-- <a href="{{'https://twitter.com/'}}" (click)="callHeaderCTEvent('https://twitter.com/'); _sharedService.checkCTALink($event, 'https://twitter.com/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.991 19.459">
                  <g id="Twitter" transform="translate(0 -3.002)">
                    <path id="Path_2" data-name="Path 2"
                      d="M45.6,21.46A13.87,13.87,0,0,0,59.592,7.465V6.8a10.834,10.834,0,0,0,2.4-2.532,11.064,11.064,0,0,1-2.8.8A5.186,5.186,0,0,0,61.325,2.4a12.225,12.225,0,0,1-3.066,1.2,4.76,4.76,0,0,0-3.6-1.6,5.008,5.008,0,0,0-4.932,4.932A2.6,2.6,0,0,0,49.862,8a13.78,13.78,0,0,1-10.13-5.2,5.1,5.1,0,0,0-.666,2.532A5.3,5.3,0,0,0,41.2,9.464,4.493,4.493,0,0,1,38.933,8.8h0a4.871,4.871,0,0,0,4,4.8,4.109,4.109,0,0,1-1.333.133,2.268,2.268,0,0,1-.933-.133,5.049,5.049,0,0,0,4.665,3.465A10.063,10.063,0,0,1,39.2,19.194,3.69,3.69,0,0,1,38,19.06a12.581,12.581,0,0,0,7.6,2.4"
                      transform="translate(-38 1.003)" fill="#4b4847" fill-rule="evenodd" />
                  </g>
                </svg>
              </a> -->
              <a href="{{'https://www.linkedin.com/company/jioworldcentre/'}}" aria-label="Social icons" (click)="callHeaderCTEvent('https://www.linkedin.com/company/jioworldcentre/'); _sharedService.checkCTALink($event, 'https://www.linkedin.com/company/jioworldcentre/', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21.186 21.186">
                  <path id="Linkedin"
                    d="M16.8,21.186V14.307c0-1.644-.025-3.755-2.279-3.755-2.287,0-2.629,1.792-2.629,3.632v7H7.506V7.034h4.2V8.973h.057a4.612,4.612,0,0,1,4.157-2.282c4.443,0,5.268,2.928,5.268,6.74v7.754Zm-12.064,0H.343V7.034H4.737V21.185h0ZM7.5,7.034h.008ZM0,2.552A2.548,2.548,0,1,1,2.548,5.1,2.553,2.553,0,0,1,0,2.552Z"
                    fill="#4b4847"></path>
                </svg>
              </a>
              <a href="{{'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'}}" aria-label="Social icons" (click)="callHeaderCTEvent('https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg'); _sharedService.checkCTALink($event, 'https://www.youtube.com/channel/UC2CvC-vtUDFPU87LZ3mQHrg', '_blank', 'External')" class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.676 22.162">
                <path id="Youtube" d="M13.338,21.162h0c-.069,0-6.97-.051-9.061-.288-.112-.027-.249-.047-.408-.07a3.709,3.709,0,0,1-2.536-1.228A7.333,7.333,0,0,1,.276,16.593c0-.024-.276-2.435-.276-4.857V9.474c0-2.42.273-4.881.276-4.906A6.667,6.667,0,0,1,1.334,1.587,3.6,3.6,0,0,1,3.921.344h.005L4,.337C7.683.052,13.282,0,13.338,0s5.655.052,9.337.337L22.8.347a3.367,3.367,0,0,1,2.541,1.239A7.333,7.333,0,0,1,26.4,4.569c0,.025.276,2.484.276,4.906v2.261c0,2.373-.273,4.833-.276,4.857a7.335,7.335,0,0,1-1.058,2.982,3.6,3.6,0,0,1-2.588,1.243h-.005l-.074.007C18.992,21.158,13.394,21.162,13.338,21.162ZM10.118,5.723V16.16l8.739-5.1Z" transform="translate(0.5 0.5)" fill="#4b4847" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"></path>
              </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
  <!-- mobile navbar ends -->
  <app-login [selectedScreen]="loginScreen" (isOtpVerified)="userLoggedIn($event)"></app-login>
</div>
<div style="display: none;">
  <ng-container *ngFor="let item of exploreImgArr">
    <img style="width:0;height:0;line-height: 0;visibility: hidden;opacity: 0;"
      [src]="'./assets/images/menu/' + item + '.jpg'" [alt]="item + '.jpg'">
  </ng-container>
</div>