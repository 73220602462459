import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../../shared/services/shared.service';
import { constants } from '../../../shared/shared.constant';
declare var jQuery: any;

@Component({
  selector: 'app-venue-hire-garden',
  templateUrl: './venue-hire-garden.component.html',
  styleUrls: ['./venue-hire-garden.component.css']
})
export class VenueHireGardenComponent implements OnInit, OnDestroy {
  venueHireGardenInquiryForm: FormGroup;
  inquiryType = 'Venue Hire';
  brand = 'Jio World Garden';
  typeOfEvent;
  countryCode;
  errorText = 'Field is required';
  submitted = false;
  disableBtn;
  @ViewChild("eventFromDate", { static: true }) eventFromDate: any;
  @ViewChild("eventFromTime", { static: true }) eventFromTime: any;
  @ViewChild("eventToDate", { static: true }) eventToDate: any;
  @ViewChild("eventToTime", { static: true }) eventToTime: any;
  @Output() inquiryTypeValue = new EventEmitter();
  @Output() brandValue = new EventEmitter();
  @Input() inquiryData;
  @Input() captchaSiteKey;
  @Input() set disableSubmitBtn(value) {
    this.disableBtn = value;
  }
  venueGardenData;
  venueGardenArrData;
  jArr = ['#eventFromDate', "#eventToDate"];
  eventDateError;
  isCaptchaSelected = false;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;  
  apiErrorMsg="";

  grcToken =  "XXXXXXXXXXX";

  constructor(private formBuilder: FormBuilder, public _sharedService: SharedService, private router: Router) {
    this._sharedService.inquiryApiErrorMsg.subscribe(
      (data) => {
        if (data['error'].message) {
          this.apiErrorMsg = "Server error. Please try after some time...";
          // this.apiErrorMsg = data['error'].message;
        }
      }
    );
   }

  ngOnInit() {
    let prefilledData = this._sharedService.getInquiryFormValues();
    this.venueGardenData = this.inquiryData.cards[1];
    this.venueGardenArrData = this.venueGardenData.garden;
    this._sharedService.callCustomDateTimePicker();
    this._sharedService.callInquiryPeopleRangeSlider();
    this.venueHireGardenInquiryForm = this.formBuilder.group({
      inquiryType: ['Venue Hire'],
      brand: ['JWG'],
      nameInput: [(prefilledData.name)?prefilledData.name:'', Validators.required],
      companyInput: [(prefilledData.company)?prefilledData.company:''],
      emailInput: [(prefilledData.email)?prefilledData.email:'', [Validators.required, Validators.pattern(constants.EMAIL_REGEX)]],
      messageInput: [(prefilledData.message)?prefilledData.message:'', Validators.required],
      countryCode: ['+91'],
      phoneNoInput: [(prefilledData.phoneNo)?prefilledData.phoneNo:'', Validators.minLength(10)],
      typeOfEvent: [this._sharedService.configData.default_enquiries_dropdown_value || 'Did not say'],
      eventDateTime: this.formBuilder.group({
        fromDateInput: [''],
        fromTimeInput: ['9:00 AM'],
        endDateInput: [''],
        endTimeInput: ['9:00 AM']
      }),
      numberOfPeopleInput: [''],
      tcCheckbox: [false, Validators.requiredTrue]
    });
    this._sharedService.callCustomDropdown();
    this.jArr.forEach(item => {
      this.dynamicjQueryFn(item);
    });
  }

  get venueHireGardenFormControls() { return this.venueHireGardenInquiryForm.controls; }

  setDropdownValue(formControlname, value) {
    this.venueHireGardenInquiryForm.get(formControlname).setValue(value);
  }

  dynamicjQueryFn(id) {
    jQuery('body').on('change', id, function() {    
      jQuery(id).trigger('click');
    });
  }

  sendVgRouteObj(name) {
    this.router.navigate(['/legal'], {
      state: { tabName: name }
    });
    return false;
  }

  venueHireGardenSubmit(form) {
    this.submitted = true;
    // if(!this.isCaptchaSelected) return;
    if (form.invalid || !this.isCaptchaSelected) {
      return;
    }
    this.disableBtn = true;
    let postData = form.value;
    postData.grcToken = this.grcToken;
    let venuHireGardenEveObj = {
      Enquiry_Type: postData.inquiryType,
      Brand_Name: postData.brand,
      Event_Type: postData.typeOfEvent,
      Event_From_Date: this.venueHireGardenInquiryForm.get(['eventDateTime', 'fromDateInput']).value,
      Event_End_Date: this.venueHireGardenInquiryForm.get(['eventDateTime','endDateInput']).value,
      Guests_Count: postData.numberOfPeopleInput,
      Organiser_Name: postData.nameInput,
      Organiser_Email: postData.emailInput,
      Organiser_Phone: postData.countryCode + postData.phoneNoInput,
      Organiser_Company_Name:postData.companyInput
      // Event_Name: 'Enquiry_Forms'
    };
    let venuHireGardenCTProfile = {
      Organiser_Name: postData.nameInput,
      Organiser_Email: postData.emailInput,
      Organiser_Phone: postData.countryCode + postData.phoneNoInput,
      Organiser_Company_Name:postData.companyInput
    };
    this._sharedService.submitInquiryForm(postData, venuHireGardenEveObj, venuHireGardenCTProfile);
    // console.log("form values:", form.value);
  }

  updateFromDate(value, formName, controlName) {
    let tmp = this.venueHireGardenInquiryForm.get([formName, controlName]).value;
    if(tmp == value) return;
    this.checkDateValidation(value, formName, controlName);
    // if (this.eventDateError || this.dismantleDateError || this.setupDateError) return;

    this.venueHireGardenInquiryForm.patchValue({
      [formName]: {
        [controlName]: value
      }
    });
    // console.log("value", value);
  }

  checkDateValidation(value, formName, controlName) {
    let err = '';
    if(controlName == 'endDateInput') {
      err = (this.venueHireGardenInquiryForm.get([formName, 'fromDateInput']).value)?
          ((value < this.venueHireGardenInquiryForm.get([formName, 'fromDateInput']).value)?
          'End date must be greater than start date': '')
        : 'Please select Start Date';
    }else if(controlName == 'fromDateInput' && this.venueHireGardenInquiryForm.get([formName, 'endDateInput']).value) {
      err = (value > this.venueHireGardenInquiryForm.get([formName, 'endDateInput']).value)?
      'End date must be greater than start date': '';
    }
    this.eventDateError = err;
  }

  vGardenCaptchaRes($event, flag?) {
    if(!$event) return this.isCaptchaSelected = false;
    if(flag) return console.log('Something went long when loading the Google reCAPTCHA');
    this.isCaptchaSelected = true
    this.grcToken = $event;
  }

  ngOnDestroy() {
    let obj = {
      name: (this.venueHireGardenFormControls.nameInput.value)?this.venueHireGardenFormControls.nameInput.value : '',
      email: (this.venueHireGardenFormControls.emailInput.value)?this.venueHireGardenFormControls.emailInput.value : '',
      company: (this.venueHireGardenFormControls.companyInput.value)?this.venueHireGardenFormControls.companyInput.value : '',
      message: (this.venueHireGardenFormControls.messageInput.value)?this.venueHireGardenFormControls.messageInput.value : '',
      phoneNo: (this.venueHireGardenFormControls.phoneNoInput.value)?this.venueHireGardenFormControls.phoneNoInput.value : ''
    }
    this._sharedService.setInquiryFormValues(obj.name, obj.email, obj.company, obj.message, obj.phoneNo);
  }

}
