import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
  })
  export class DevelopmentGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate() {
        if(environment.env !== 'dev'){
            this.router.navigate(['/']);
        }    
        return environment.env === 'dev';
    }
  }