import { isPlatformBrowser, isPlatformServer, Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Injector, PLATFORM_ID } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })

export class AppInitService {
  checkBrowser: boolean = false;
  flag = true;
  constructor(private httpClient: HttpClient,  @Inject(PLATFORM_ID) private platformId: string, @Inject(Location) private location: any) {
    this.checkBrowser = isPlatformBrowser(platformId);
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  loadUrls(): Promise<any> {
    const platFormLocation = this.location._platformLocation;
    console.log(platFormLocation);
    const port = platFormLocation.port && platFormLocation.port != '' ? ':' + platFormLocation.port : '';
    console.log(port)
    const baseHref = `${platFormLocation.protocol}//${platFormLocation.hostname}${port}/`;
    console.log(baseHref);
    // if(this.flag) {
    //   console.log(platFormLocation);
    //   console.log(baseHref);
    //   this.flag = false;
    // }
    const promise = this.httpClient
    
    .get( (baseHref.includes('webcache') ? 'https://www.jioworldcentre.com/' : baseHref) + "/assets/config/environment.json")
    .toPromise()
    .then((env: any) => {
      environment.production = env.production;
      environment.env = env.env;
      environment.getComponentUrl = env.getComponentUrl;
      environment.staticUrlForImgNew = env.staticUrlForImgNew;
      environment.getSubscriptionUrl = env.getSubscriptionUrl;
      environment.getOtpUrl = env.getOtpUrl;
      environment.instaFeedUrl = env.instaFeedUrl;
      environment.exhibitorLogin = env.exhibitorLogin;
      environment.recaptchaSiteKey = env.recaptchaSiteKey;
      environment.checkEnv = env.checkEnv;
      environment.tccUrl = env.tccUrl;
      environment.certificateURL = env.certificateURL;
      console.log({jsonEnv: environment});
      return env;
    });
    return promise;
    
  }
}
