import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './containers/landing-page/landing-page.component';
import { ConventionCenterLandingComponent } from './containers/convention-center-landing/convention-center-landing.component';
import { ThankYouPageComponent } from './views/thank-you-page/thank-you-page.component';
import { JioworldNitaAmbaniComponent } from './containers/jioworld-nita-ambani/jioworld-nita-ambani.component';
import { GardenPageComponent } from './containers/garden-page/garden-page.component';
import { P404Component } from './shared/components/error/404.component';
import { CecWalkthroughComponent } from './containers/convention-center-landing/cec-walkthrough/cec-walkthrough.component';
import { GardenVenueDetailsComponent } from './containers/garden-page/garden-venue-details/garden-venue-details.component';
import { PressAndMediaComponent } from './containers/press-and-media/press-and-media.component';
import { FooterFaqsComponent } from './containers/footer-faqs/footer-faqs.component';
import { LegalPolicyComponent } from './views/footer/legal-policy/legal-policy.component';
import { NewsroomComponent } from './containers/newsroom/newsroom.component';
import { DownloadableTechSpecsComponent } from './views/downloadable-tech-specs/downloadable-tech-specs.component';
import { GardenArchivesComponent } from './containers/garden-page/garden-archives/garden-archives.component';
import { Covid19PageComponent } from './containers/covid19-page/covid19-page.component';
import { CecVenueDetailsComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-details/cec-venue-details.component';
import { CookiePolicyComponent } from './containers/cookie-policy/cookie-policy.component';
import { GardenFacilitiesComponent } from './containers/garden-page/garden-venue-details/garden-facilities/garden-facilities.component';
import { CecVenueListingComponent } from './containers/convention-center-landing/cec-venue-listing/cec-venue-listing.component';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { InquiryHomeComponent } from './views/inquiry-home/inquiry-home.component';
import { GetDirectionPageComponent } from './containers/get-direction-page/get-direction-page.component';
import { InquiryThankYouComponent } from './views/inquiry-home/inquiry-thank-you/inquiry-thank-you.component';
import { CulturalCentrePageComponent } from './containers/cultural-centre-page/cultural-centre-page.component';
import { CecServicePageComponent } from './containers/convention-center-landing/cec-service-page/cec-service-page.component';
import { AboutUsComponent } from './containers/about-us/about-us.component';
import { GardenSafetyComponent } from './containers/garden-page/garden-safety/garden-safety.component';
import { UpgradeBrowserComponent } from './containers/upgrade-browser/upgrade-browser.component';
import { CampaignChatbotComponent } from './containers/campaign-chatbot/campaign-chatbot.component';
import { LegalPolicyTestComponent } from './views/footer/legal-policy-test/legal-policy-test.component';
import { DasComponent } from './containers/das/das.component';
import { NewsArticleComponent } from './views/news-article/news-article.component';
import { CecEventDetailsComponent } from './containers/convention-center-landing/cec-event-details/cec-event-details.component';
import { GuestGuideComponent } from './containers/convention-center-landing/guest-guide/guest-guide.component';
import { SignInComponent } from './views/header/sign-in/sign-in.component';
import { LogisticsServicesComponent } from './containers/exhibitor-logistics/logistics-services/logistics-services.component';
import { LogisticsRedirectComponent } from './containers/exhibitor-logistics/logistics-redirect/logistics-redirect.component';
import { DevelopmentGuard } from './shared/services/development-guard.service';
import { JioworldNitaAmbaniCCComponent } from './containers/jioworld-nita-ambani-cc/jioworld-nita-ambani-cc.component';
import { cecEventResolver } from './shared/services/resolvers/cec-event-resolver.service';
import { CareersComponent } from './containers/careers/careers.component';
import { MetaResolver } from './shared/services/resolvers/meta-resolver.service';
import { DasNotificationsComponent } from './views/das-notifications/das-notifications.component';
//import { EditNotificationsComponent } from './views/das-notifications/components/edit-notifications/edit-notifications.component';
import { NotificationComponent } from './views/header/notification/notification.component';
import { CecEventComponent } from './containers/convention-center-landing/cec-event/cec-event.component';
import { CecWeddingEventComponent } from './containers/convention-center-landing/cec-wedding-event/cec-wedding-event.component';
import { CecWeddingDetailsComponent } from './containers/convention-center-landing/cec-wedding-event/cec-wedding-details/cec-wedding-details.component';
import { SmallMeetingRoomsComponent } from './containers/convention-center-landing/small-meeting-rooms/small-meeting-rooms.component';

const routes: Routes = [
  // { path: "", redirectTo:"das", pathMatch: 'full', data: {title: 'Dhirubhai Ambani Square', page: 'new-das', CTLinkClicked:'DAS', newHF: true, notificationId:'3'} },  
  { path: "", component: LandingPageComponent, pathMatch: 'full', data: { 'title': 'Jio World Centre - Official Website', metatags: { description: "The Jio World Centre is a stunning new landmark located in the heart of Mumbai, spread over an area of 18.5 acres. Know more about the centre and its offerings.", keywords: "" }, page: 'home', CTLinkClicked: 'Jio World Centre', sticky: true, landingPage: true, notificationId: '1' },
    resolve: {
    data: MetaResolver
    }
  },
  {
    path: "home",
    redirectTo: '/',
    pathMatch: 'full'
    //component: LandingPageComponent, 
    // data: {'title': 'Jio World Centre (JWC) - Official Website', metatags: {description:"The Jio World Centre is a stunning new landmark located in the heart of Mumbai, spread over an area of 18.5 acres. Know more about the centre and its offerings.", keywords:""}, page: 'home', CTLinkClicked:'Jio World Centre', sticky: true, landingPage: true, notificationId:'1'} 
  },
  { path: "jio-world-convention-centre", component: ConventionCenterLandingComponent, data: { title: 'Jio World Centre - Convention Centre - Where Events Come to Life', metatags: { description: "Explore the Jio World Convention Centre, where events come to life. It comprises India's largest and finest convention & exhibition hall, a ballroom & 25 meeting rooms.", keywords: "convention centre, convention halls, exhibition halls, ballroom, meeting rooms" }, page: "cec", CTLinkClicked: 'Jio World Convention Centre', gtagConversion: 'page-view', notificationId: '2' },
   resolve: {
    data: MetaResolver
  }
 },
  { path: "jio-world-convention-centre/venues", component: CecVenueListingComponent, data: { title: 'Jio World Centre - Convention Centre Venues', metatags: { description: "Spread over 32,000 sq. m., the Jio World Convention Centre houses India's largest and finest convention hall & exhibition hall, a ballroom and 25 meeting rooms", keywords: "convention halls, exhibition halls, ballroom, meeting rooms" }, page: "cec", CTLinkClicked: 'CEC Venues' },
  resolve: {
   data: MetaResolver
 } },
  { path: "jio-world-convention-centre/venues/:room", component: CecVenueDetailsComponent, data: { title: 'Convention Centre Venue Detail', metatags: { description: "The Jio World Convention Centre houses the largest exhibition hall in India - the Pavilion. It has digitally equipped spaces, LED display walls, 5G connectivity & more.", keywords: "exhibition halls, event space, virtual expo hall" }, page: "cec", CTLinkClicked: 'CEC Venue Details' },
  resolve: {
   data: MetaResolver
 } },
  { path: "jio-world-convention-centre/venues/:room/walkthrough", component: CecWalkthroughComponent, data: { title: 'CEC Walkthrough', metatags: { description: "Venue Walkthrough - Explore the Jio World Convention Centre & find out more about its spacious exhibition halls, also known as the Pavilions", keywords: "exhibition halls walkthrough, venue walkthrough" }, page: "cec", CTLinkClicked: 'CEC Walkthrough' },
  resolve: {
   data: MetaResolver
 } },
  { path: ":brand/documents-and-downloads", component: DownloadableTechSpecsComponent, data: { title: 'Downloads', page: "cec" },
  resolve: {
   data: MetaResolver
 } },
  { path: "jio-world-convention-centre/exhibitor-organiser-services", component: CecServicePageComponent, data: { title: 'Jio World Centre - Event Services', metatags: { description: "Explore the world-class event services that the Jio World Centre offers for various shows  held across the venues", keywords: "jio event services, venues event services" }, page: "cec", CTLinkClicked: 'CEC Services' },
  resolve: {
   data: MetaResolver
 } },
 { path: "jio-world-convention-centre/organise-your-next-event", component: CecEventComponent, data: { title: 'Jio World Centre - Event Services', metatags: { description: "Explore the world-class event services that the Jio World Centre offers for various shows  held across the venues", keywords: "jio event services, venues event services" }, page: "cec", CTLinkClicked: 'CEC Services' },
 resolve: {
  data: MetaResolver
} 
},
{ path: "jio-world-convention-centre/weddings", component: CecWeddingEventComponent, data: { title: 'Jio World Centre - Event Services', metatags: { description: "Explore the world-class event services that the Jio World Centre offers for various shows  held across the venues", keywords: "jio event services, venues event services" }, page: "cec", CTLinkClicked: 'CEC Services' },
 resolve: {
  data: MetaResolver
} 
},
{ path: "jio-world-convention-centre/weddings/:slug", component: CecWeddingDetailsComponent, data: { title: 'Jio World Centre - Event Services', metatags: { description: "Explore the world-class event services that the Jio World Centre offers for various shows  held across the venues", keywords: "jio event services, venues event services" }, page: "cec", CTLinkClicked: 'CEC Services' },
 resolve: {
  data: MetaResolver
} 
},
  {
    path: "jio-world-convention-centre/events/:cecId",
    component: CecEventDetailsComponent,
    resolve: {
      currentEvent: cecEventResolver
    }
  },
  {
    path: "events/:jioId",
    component: CecEventDetailsComponent,
    resolve: {
      currentEvent: cecEventResolver
    }
  },
  { path: "jio-world-convention-centre/guest-guide", component: GuestGuideComponent, data: { title: 'Jio World Convention Centre', page: "guest-guide", CTLinkClicked: 'CEC Guest Guide' },
  resolve: {
    data: MetaResolver
  } },
  { path: "nmacc", redirectTo:'/nita-mukesh-ambani-cultural-centre',
  resolve: {
    data: MetaResolver
  } },
  { path: "nita-mukesh-ambani-cultural-centre", component: CulturalCentrePageComponent, data: { title: 'Jio World Centre - The Cultural Centre', page: 'tcc', CTLinkClicked: 'The Cultural Centre' },
  resolve: {
    data: MetaResolver
  } },
  { path: "jio-world-garden", component: GardenPageComponent, data: { title: 'Jio World Garden - Mumbai', page: "garden", CTLinkClicked: 'Jio World Garden' },
  resolve: {
    data: MetaResolver
  } },
  { path: "jio-world-garden/about", component: GardenVenueDetailsComponent, data: { title: 'Jio World Garden - About', page: "garden", CTLinkClicked: 'JWG Venue Details' },
  resolve: {
    data: MetaResolver
  } },
  { path: "jio-world-garden/events-archive", component: GardenArchivesComponent, data: { title: 'Jio World Garden - Events Archive', page: "garden", CTLinkClicked: 'JWG Archive' },
  resolve: {
    data: MetaResolver
  } },
  { path: "jio-world-garden/facilities", component: GardenFacilitiesComponent, data: { title: 'Jio World Garden - Downloads', page: "garden", CTLinkClicked: 'JWG Facilities' },
  resolve: {
    data: MetaResolver
  } },
  { path: "careers", component: CareersComponent, data: { title: 'Jio World Centre - Careers', page: "", CTLinkClicked: '' } ,
  resolve: {
    data: MetaResolver
  }},
  // { path: "press-and-media", component: PressAndMediaComponent, data: {title: 'Press and Media', page: 'home', CTLinkClicked:'Press and Media', sticky: true} },
  { path: "newsroom", component: NewsroomComponent, data: { title: 'Newsroom', page: 'home', CTLinkClicked: 'Newsroom', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "enquiries", component: InquiryHomeComponent, data: { title: 'Jio World Centre - Enquiries', metatags: { description: "Jio World Centre - Reach out to us for enquiries related to the offerings, bookings, facilities, venues & services", keywords: "" }, page: 'home', CTLinkClicked: 'Enquiries', sticky: true } ,
  resolve: {
    data: MetaResolver
  }},
  { path: "thank-you", component: InquiryThankYouComponent, data: { title: 'Thank You', gtagConversion: 'lead' },
  resolve: {
    data: MetaResolver
  } },
  { path: "health-and-covid-safety-measures", 
  redirectTo: '/',
  // component: Covid19PageComponent, 
  data: { title: 'Jio World Centre - Health and Covid Safety Measure', metatags: { description: "Keeping the comfort & convenience of our guests at the core, the Jio World Centre has implemented strict safety measures with a host of contactless services at each venue", keywords: "jio world centre safety" }, page: "safety", CTLinkClicked: 'COVID Safety Guide', sticky: true } ,
  resolve: {
    data: MetaResolver
  }},
  { path: "jio-world-garden/visit-safely", component: GardenSafetyComponent, data: { title: 'Jio World Garden - Visit Safely', page: 'garden', CTLinkClicked: 'Visit Safely', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "dhirubhai-ambani-square", component: DasComponent, data: { title: 'Dhirubhai Ambani Square', page: 'das', CTLinkClicked: 'DAS', gtagConversion: 'page-view', notificationId: '3' },
  resolve: {
    data: MetaResolver
  }
  },
  { path: "dhirubhai-ambani-square/visit-safely", component: Covid19PageComponent, data: { title: 'Dhirubhai Ambani Square - Visit Safely', page: 'das', CTLinkClicked: 'DAS Visit Safely', sticky: true, notificationId: '3' } ,
  resolve: {
    data: MetaResolver
  }},
  { path: "frequently-asked-questions", component: FooterFaqsComponent, data: { title: 'Jio World Centre - FAQs', metatags: { description: "Jio World Centre - Browse frequently asked questions on topics related to the centre, its venues, facilities, services, offerings & experiences", keywords: "" }, page: 'faq', CTLinkClicked: 'FAQs', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "legal", component: LegalPolicyComponent, data: { title: 'Jio World Centre - Legal Policies', CTLinkClicked: 'Legal', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "cookie-policy", component: CookiePolicyComponent, data: { title: 'Cookie Policy' },
  resolve: {
    data: MetaResolver
  } },
  { path: "contact-us", component: ContactUsComponent, data: { title: 'Jio World Centre - Contact Us', metatags: { description: "Jio World Centre - Contact us to resolve all your event or media related queries ", keywords: "convention centre contact info" }, CTLinkClicked: 'Contact', sticky: true } ,
  resolve: {
    data: MetaResolver
  }},
  { path: "get-directions", component: GetDirectionPageComponent, data: { title: 'Jio World Centre - Get Directions', CTLinkClicked: 'Visit Us', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "about", component: AboutUsComponent, data: { title: 'Jio World Centre - All About Us', metatags: { description: "Know all about the Jio World Centre, the stunning new landmark of Mumbai and a global destination that houses the largest convention centre in the country", keywords: "convention centre" }, CTLinkClicked: 'About​', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  { path: "upgrade-browser", component: UpgradeBrowserComponent, data: { title: 'Upgrade Browser' },
    resolve: {
        data: MetaResolver
      }},
  { path: "campaign-chatbot", component: CampaignChatbotComponent, data: { page: 'chatbot' } ,
    resolve: {
        data: MetaResolver
      }},
  { path: "legal-test", component: LegalPolicyTestComponent, data: { page: 'legal test' },
    resolve: {
        data: MetaResolver
      } },
  {
    path: "press-releases/:brand/:slug", component: NewsArticleComponent
    , data: { title: 'Jio World Centre - Launching the Convention Centre', CTLinkClicked: 'News Article' },
    resolve: {
      data: MetaResolver
    }
  },
  
 
  { path: "das-notifications", component: DasNotificationsComponent,  data: { title: 'Jio World Centre - Das-Notifications', CTLinkClicked: '', page: 'das-notifications' },
  resolve: {
    data: MetaResolver
  }},
  //{ path: "das-notifications-edit", component:  EditNotificationsComponent,  data: { title: 'Jio World Centre - Das-Notifications-edit', CTLinkClicked: '', page: '' } },
 
  { path: "sign-in", component: SignInComponent, canActivate: [DevelopmentGuard], data: { title: 'Jio World Centre - Signin', CTLinkClicked: 'Signin', page: 'sign-in' },
  resolve: {
    data: MetaResolver
  }},
  { path: 'notification',component: NotificationComponent},
  { path: "logistics-services", component: LogisticsServicesComponent, data: { title: 'Jio World Centre - Logistics Services', CTLinkClicked: 'Logistics Services', sticky: true }, resolve: {
    data: MetaResolver
  }},
  { path: "logistics-services-redirect", component: LogisticsRedirectComponent, data: { title: 'Jio World Centre - Logistics Services Redirect', CTLinkClicked: 'Logistics Services Redirect', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  // { path: "tcc", component: JioworldNitaAmbaniCCComponent, data: { title: 'Nita Ambani Cultural Centre', CTLinkClicked: 'Nita Ambani Cultural Centre', sticky: false } },
  { path: "not-found", component: P404Component, data: { title: 'Jio World Centre - Page not found', page: 'home', sticky: true },
  resolve: {
    data: MetaResolver
  } },
  // Renaming 2nd instance of DAS as /das as it is not going live  
  // { path: '', redirectTo: '/das', pathMatch: 'full' },
  // { path: "", component: DasComponent, data: {title: 'Dhirubhai Ambani Square', page: 'new-das', CTLinkClicked:'DAS', newHF: true, notificationId:'3'} },
  // { path: "not-found" , component: P404Component, data: {title: 'Dhirubhai Ambani Square - Page not found', page: 'new-das', sticky: true, newHF: true, newHeaderDesk: true} },
  { path: "das", component: DasComponent, data: { title: 'Dhirubhai Ambani Square', page: 'new-das', CTLinkClicked: 'DAS', newHF: true, landingPage: true, notificationId: '3' },
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/legal", component: LegalPolicyComponent, data: { title: 'Dhirubhai Ambani Square - Legal Policies', page: 'new-das', CTLinkClicked: 'DAS Legal', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/visit-safely", component: Covid19PageComponent, data: { title: 'Dhirubhai Ambani Square - Visit Safely', page: 'new-das', CTLinkClicked: 'DAS Visit Safely', sticky: true, newHF: true, newHeaderDesk: true, notificationId: '3' } ,
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/frequently-asked-questions", component: FooterFaqsComponent, data: { title: 'Dhirubhai Ambani Square - Frequently Asked Questions', page: 'new-das', CTLinkClicked: 'DAS FAQs', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/get-directions", component: GetDirectionPageComponent, data: { title: 'Dhirubhai Ambani Square - Get Directions', page: 'new-das', CTLinkClicked: 'DAS Visit Us', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/newsroom", component: NewsroomComponent, data: { title: 'Newsroom', page: 'new-das', CTLinkClicked: 'Newsroom', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},
  {
    path: "das/press-releases/:brand/:slug", component: NewsArticleComponent
    , data: { title: 'Dhirubhai Ambani Square - News Article', page: 'new-das', CTLinkClicked: 'DAS article', sticky: true, newHF: true, newHeaderDesk: true },
  resolve: {
    data: MetaResolver
  }

  },
  { path: "das/upgrade-browser", component: UpgradeBrowserComponent, data: { title: 'Upgrade Browser', page: 'new-das', sticky: true, newHF: true, newHeaderDesk: true },
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/contact", component: ContactUsComponent, data: { title: 'Dhirubhai Ambani Square - Contact', CTLinkClicked: 'Contact', page: 'new-das', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},
  { path: "das/thank-you", component: InquiryThankYouComponent, data: { title: 'Thank You', page: 'new-das', sticky: true, newHF: true, newHeaderDesk: true } ,
  resolve: {
    data: MetaResolver
  }
},

{ path: "jio-world-convention-centre/venues/meeting-rooms/meeting-pods", component: SmallMeetingRoomsComponent
},
  { path: "**", redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
