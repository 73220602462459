<div class="loader">
  <div class="loader_spin"></div>
</div>
<section class="cookie_policy position-fixed fixed-bottom">
  <div class="container">
    <div class="section_heading">
      <div class="row">
        <div class="col-md-12">
          <p class="section_heading--text content3">By using our site, you acknowledge that you have read and understood our <a href="{{'/legal?tab=privacy'}}" (click)="_sharedService.checkCTALink($event, '/legal?tab=privacy')">Privacy Policy</a> as well as our
            <a href="{{'/legal?tab=terms'}}" (click)="_sharedService.checkCTALink($event, '/legal?tab=terms')"> Terms and Conditions </a></p>

          <div class="buttons">
            <a href="javascript:void(0)" (click)="hideCookieConsent()" 
              class="cta-prim color-gold emp-btn" role="button">
              <span class="cta-prim-txt">yes, accept cookies</span>
            </a>
            <!-- <a href="#" class="cta-sec color-gold"><span class="cta-sec-txt">No, know More </span></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>