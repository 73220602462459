<div class="loader">
    <div class="loader_spin"></div>
</div>
<main role="main">
    <app-back-to-wrapper></app-back-to-wrapper>

    <div class="kma_with_thumbnails  ">
        <div class="breadcrumbs mb-0 kma_breadcrumbs">
            <div class="breadcrumbs_container">
                <a href='/'>Jio World Centre </a> / <a href='/jio-world-convention-centre'>Jio World Convention
                    Centre</a> / <a href='/jio-world-convention-centre/weddings'>Weddings</a> /
                <strong>{{currentEvent?.EventTitle?.data}}</strong>
            </div>
        </div> <!-- Swiper -->
        <div class="swiper-container kma_with_thumbnails_big_img"
            *ngIf="currentEvent?.HideBannerSlider?.data != 'true'">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let eventItem of currentEvent?.AddBannerSlider; let i=index;">
                    <ng-container *ngIf="eventItem?.BackgroundImageVideo?.data=='image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.UploadMobileKMAImageVideo?.document?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                    </ng-container>
                    <ng-container *ngIf="eventItem?.BackgroundImageVideo?.data=='video'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a href="javascript:;" class="play_href watchVideoClick blue"
                            [attr.data-url]=" staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl"
                            data-target="#video_box" data-toggle="modal">
                            <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
                        </a>
                    </ng-container>
                    <ng-container
                        *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video' || eventItem?.BackgroundImageVideo?.data=='360 image'">
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--deskop jwc-mt-35" />
                        <img src="{{ staticUrlForImgNew + eventItem?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}"
                            alt="{{eventItem?.ImageAltText?.data}}" class="big_img--mobile" />
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 video'" href="javascript:;"
                            class="jwc_360tour_btn play360"
                            [attr.videosrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                            <div class="jwc_360tour_btn--icon mb-0">
                                <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                            </div>
                        </a>
                        <a *ngIf="eventItem?.BackgroundImageVideo?.data=='360 image'" href="javascript:;"
                            class="jwc_360tour_btn play360"
                            [attr.imgsrc]="staticUrlForImgNew + eventItem?.UploadImageVideo?.document?.contentUrl">
                            <div class="jwc_360tour_btn--icon mb-0">
                                <img src="./assets/images/360/360.svg" class="img-fluid" alt="360 icon">
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
            <!-- Add pagination -->
            <div class="swiper-pagination"></div>
        </div>

    </div>

    <app-video-popup></app-video-popup>

    <section class="event_short_details" [attr.style]="('--data-details-bgColor:#004EA8') | safe: 'style'">
        <div class="container">
            <div class="event_short_details_content">
                <div class="event_short_details_title">
                    <h4>{{currentEvent?.EventTitle?.data}}</h4>
                </div>
                <div class="event_short_details_link" *ngIf="currentEvent?.HideCTAText?.data !== 'true'">
                    <a href="{{currentEvent?.CTALink?.data}}" class="cta-prim color-white emp-btn mb-0"
                        (click)="_sharedService.checkCTALink($event, currentEvent?.CTALink?.data, currentEvent?.CTALinkWindowPreference?.data)">
                        <span class="cta-prim-txt">{{currentEvent?.CTAText?.data}}</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="cec_event_details">
        <div class="container">
            <div class="row justify-content-between ">
                <div class="cec_event_details_title col-lg-7 col-md-7">
                    <div class="section_heading section_heading--title section_heading--text    "
                        [attr.style]="('--data-titleColor:#4B4847;--data-lineColor:#004EA8') | safe: 'style'">
                        <h2>{{currentEvent?.EventDetailsHeader?.data}}</h2>
                    </div>

                </div>
                <div class="clear"></div>
                <div class="col-lg-7 col-md-7 cec_event_details_text">
                    <p [innerHtml]="currentEvent?.EventDetailsContent?.data"></p>
                </div>
                <div class="col-lg-4 col-md-4 cec_event_details_data">
                    <div class="cec_event_details_data_each">
                        <h4>{{currentEvent?.EventKeyOfferingsLabel?.data}}</h4>
                        <!-- <p class="content3" [innerHtml]="currentEvent?.EventKeyOfferings?.data"></p> -->
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-02.svg"
                                alt="WEDDING-ICONS-02.svg">
                            <p class="content3 m-0">Exclusive panel of wedding décor partners</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-01.svg"
                                alt="WEDDING-ICONS-01.svg">
                            <p class="content3 m-0">Suggested list of leading wedding experience planners</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-06.svg"
                                alt="WEDDING-ICONS-06.svg">
                            <p class="content3 m-0">Dedicated personal shopper</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-05.svg"
                                alt="WEDDING-ICONS-05.svg">
                            <p class="content3 m-0">Recommended list of wedding trousseau designers</p>
                        </div>
                        
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-07.svg"
                                alt="WEDDING-ICONS-07.svg">
                            <p class="content3 m-0">Menu consultation with JWCC’s Culinary Team</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-04.svg"
                                alt="WEDDING-ICONS-04.svg">
                            <p class="content3 m-0">Suggestion of latest trends from our sommeliers and mixologists</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-03.svg"
                                alt="WEDDING-ICONS-03.svg">
                            <p class="content3 m-0">Exclusive food & wine tasting sessions</p>
                        </div>
                        <div class="d-flex mb-3">
                            <img class="img-fluid wedding-icon" src="./assets/images/cec-wedding/WEDDING-ICONS-08.svg"
                                alt="WEDDING-ICONS-08.svg">
                            <p class="content3 m-0">Recommended culinary specialists for bespoke F&B experiences</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="pt-0" *ngIf="currentEvent?.HideGallery?.data !== 'true'">
        <div class="jwc_image_slider jwc_swiper_normal swiper_pagination_no_number" data-aos="fade-up" data-interval="0"
            data-space="0" data-slides="1" data-ipad-slides="1" data-ipad-space="0" data-mobile-slides="1"
            data-mobile-space="0">
            <div class="container">
                <div class="section_heading section_heading--title section_heading--text text-center "
                    [attr.style]="('--data-lineColor:#004EA8') | safe: 'style'">
                    <h2 [innerHTML]="'Gallery'"></h2>
                </div>
        
            </div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of currentEvent?.Gallery">
                        <div class="jwc_image_slider_each"
                            *ngIf="item?.BackgroundImageVideoForBanner?.data=='image' || item?.BackgroundImageVideoForBanner =='image'">
                            <img src="{{staticUrlForImgNew + (item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner)}}"
                                class="img-fluid"
                                alt="{{item?.ImageAltTextForBanner?.data || item?.ImageAltTextForBanner}}">
                        </div>
                        <div class="jwc_image_slider_each"
                            *ngIf="item?.BackgroundImageVideoForBanner?.data=='video' || item?.BackgroundImageVideoForBanner =='video'">
                            <a href="javascript:;" class="watchVideoClick" data-target="#video_box"
                                attr.data-url="{{staticUrlForImgNew + (item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner)}}"
                                data-toggle="modal">

                                <img src="{{staticUrlForImgNew + (item?.PlaceholderImageOfBannerForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded)}}"
                                    class="img-fluid"
                                    alt="{{item?.ImageAltTextForBanner?.data || item?.ImageAltTextForBanner}}">

                                <div class="play_href default"><img src="./assets/images/playbutton.svg"
                                        class="img-fluid play_btn" alt="play button"></div>
                            </a>
                        </div>
                        <div class="jwc_image_slider_each"
                            *ngIf="item?.BackgroundImageVideoForBanner?.data=='360 image' || item?.BackgroundImageVideoForBanner =='360 image'">
                            <a href="javascript:;" class="play360"
                                attr.imgsrc="{{staticUrlForImgNew + (item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner)}}">
                                <img src="{{staticUrlForImgNew + (item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner)}}"
                                    class="img-fluid"
                                    alt="{{item?.ImageAltTextForBanner?.data || item?.ImageAltTextForBanner}}">
                                <div class="icon360"></div>
                            </a>
                        </div>
                        <div class="jwc_image_slider_each"
                            *ngIf="item?.BackgroundImageVideoForBanner?.data=='360 video' || item?.BackgroundImageVideoForBanner =='360 video'">
                            <a href="javascript:;" class="play360"
                                attr.videosrc="{{staticUrlForImgNew + (item?.UploadImageVideoForBanner?.document?.contentUrl || item?.UploadImageVideoForBanner)}}">
                                <img src="{{staticUrlForImgNew + (item?.PlaceholderImageOfBannerForVideoUploaded?.image?.contentUrl || item?.PlaceholderImageOfBannerForVideoUploaded)}}"
                                    class="img-fluid"
                                    alt="{{item?.ImageAltTextForBanner?.data || item?.ImageAltTextForBanner}}">
                                <div class="icon360"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-arrows d-none d-md-block" *ngIf="currentEvent?.Gallery.length > 1">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
            <div class="swiper-pagination d-md-none" *ngIf="currentEvent?.Gallery.length > 1"></div>
        </div>
    </section>
</main>